import React from "react";

import { Card, Grid } from "@mui/material";
import _ from "lodash";

import { DatePickerField } from "../../../components/FormField";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";

const ReportFilters = () => {
  return (
    <Card sx={{ overflow: "visible" }}>
      <SoftBox p={3}>
        <Grid container columnSpacing={2}>
          <Grid item xs={6} sm={6}>
            <DatePickerField
              label="Report From"
              name="reportFrom"
              options={{
                allowInput: false,
                time_24hr: true,
                enableTime: true,
                maxDate: "today",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DatePickerField
              label="Report To"
              name="reportTo"
              options={{
                allowInput: false,
                time_24hr: true,
                enableTime: true,
                maxDate: "today",
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <DatePickerField
              label="Generated At"
              name="generatedAt"
              options={{
                allowInput: false,
                time_24hr: true,
                enableTime: true,
                maxDate: "today",
              }}
            />
          </Grid>
        </Grid>

        <Grid container display="flex" justifyContent="space-between">
          <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
            Search
          </SoftButton>
        </Grid>
      </SoftBox>
    </Card>
  );
};

export default ReportFilters;
