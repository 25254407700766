import _ from "lodash";
import axios from "axios";
import { toastError } from "./toast";
import { getToken, removeToken } from "./cache";
import jwtDecode from "jwt-decode";

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 30 * 1000,
});

// request interceptor
request.interceptors.request.use((axiosConfig) => {
  axiosConfig.headers = {
    ...axiosConfig.headers,
    Authorization: `Bearer ${getToken()}`,
  };

  const currentTime = parseInt(new Date().getTime() / 1000);
  const jwt = getToken();
  if (jwt) {
    const expTokenTime = jwtDecode(jwt)?.exp;
    if (expTokenTime && expTokenTime - currentTime < 60) {
      removeToken();
      window.location.href = "/sign-in";
    }
  }

  return axiosConfig;
});

request.interceptors.response.use(
  (response) => _.get(response, "data.message") || _.get(response, "data"),
  (error) => {
    const config = _.get(error, "response.config");
    const data = _.get(error, "response.data");

    const statusCode = _.get(data, "statusCode");
    const method = _.get(config, "method");
    let message = _.get(data, "message[0]");
    if (
      statusCode === 401 &&
      !["post"].includes(method) &&
      error?.config?.url !== "/organizations/merchants"
    ) {
      removeToken();
      window.location.href = "/sign-in";
    }

    if (["post", "delete", "put", "patch"].includes(method)) {
      message = Array.isArray(data?.message) ? data?.message.join(" ") : data?.message;
      toastError({ description: message });
    }
    return Promise.reject(error);
  }
);

export default request;
