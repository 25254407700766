import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useGetScheduledReports } from "../../hooks";
import { useForm } from "react-hook-form";
import DataTable from "../../../../examples/Tables/DataTable";
import FormProvider from "../../../../components/FormProviders";
import _ from "lodash";
import { sanitizeRequest } from "../../schemas";
import Filters from "../../components/Filters";
import NewSchedulerModal from "../../components/NewSchedulerModal";
import SoftBox from "../../../../components/SoftBox";
import { useSchedulerStore } from "../../../../stores/scheduler";
import { fDateTimeSuffix } from "../../../../utils/formatTime";
import { Chip } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import LockClockIcon from "@mui/icons-material/LockClock";
const TABS = {
  SCHEDULED: 0,
  ONE_TIME: 1,
};

const Scheduler = () => {
  const navigate = useNavigate();
  const [value, setValue] = useState(TABS.SCHEDULED);
  const { setReport } = useSchedulerStore();

  const { mutate, isLoading, data } = useGetScheduledReports({
    defaultValues: { onlyDeleted: false },
  });
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const methods = useForm();
  const { handleSubmit, getValues } = methods;

  // create a function that returns the rows for the table
  const getRows = (type) => {
    const rows = [];
    data?.forEach((item) => {
      if (type === "scheduled" && item?.dateFrom === null && item?.dateTo === null) {
        rows.push({
          id: item._id,
          name: item.name,
          interval: _.startCase(item.reportInterval),
          nextScheduleTime: item.nextScheduleTime,
          schedule: _.startCase(item.reportSchedule),
          dateFrom: fDateTimeSuffix(item.dateFrom, " "),
          dateTo: fDateTimeSuffix(item.dateTo, " "),
          timezone: item.timezone,
          destination: item.reportDestination,
          fields: item.fields,
          emails: item.emails,
          filters: item.filters,
          maxNumberOfTransactionsInCsv: item.maxNumberOfTransactionsInCsv,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          deletedAt: item.deletedAt,
          active: (
            <Chip
              label={item.deletedAt ? "INACTIVE" : "ACTIVE"}
              color={item.deletedAt ? "error" : "success"}
            />
          ),
        });
      }
      if (type === "one-time" && item?.dateFrom !== null && item?.dateTo !== null) {
        rows.push({
          id: item._id,
          name: item.name,
          interval: _.startCase(item.reportInterval),
          nextScheduleTime: item.nextScheduleTime,
          schedule: _.startCase(item.reportSchedule),
          dateFrom: fDateTimeSuffix(item.dateFrom, " "),
          dateTo: fDateTimeSuffix(item.dateTo, " "),
          timezone: item.timezone,
          destination: item.reportDestination,
          fields: item.fields,
          emails: item.emails,
          filters: item.filters,
          maxNumberOfTransactionsInCsv: item.maxNumberOfTransactionsInCsv,
          createdAt: item.createdAt,
          updatedAt: item.updatedAt,
          deletedAt: item.deletedAt,
          active: (
            <Chip
              label={item.deletedAt ? "INACTIVE" : "ACTIVE"}
              color={item.deletedAt ? "error" : "success"}
            />
          ),
        });
      }
    });
    return rows;
  };

  // create a function that handles the form submission
  const onSubmit = (values) => {
    mutate(sanitizeRequest(values));
  };

  const onCellClick = (row) => {
    const schedulerId = row.original.id;
    const scheduler = data.find((item) => item._id === schedulerId);
    setReport(scheduler);
    navigate(`/reports/${schedulerId}`);
  };
  const refetch = () => {
    mutate(sanitizeRequest(getValues()));
  };

  useEffect(() => {
    mutate(sanitizeRequest({ onlyDeleted: false }));
  }, []);

  return (
    <SoftBox>
      <SoftBox fullWidth display="flex" justifyContent="flex-end">
        <NewSchedulerModal onClose={refetch} />
      </SoftBox>
      <SoftBox mt={2} mb={2}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Filters />
        </FormProvider>
      </SoftBox>
      <SoftBox sx={{ maxWidth: 550, marginTop: 2, ml: 2, mb: 2 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="schedulers-tabs"
            sx={{ backgroundColor: "transparent", display: { xs: "none", sm: "flex" } }}
            variant="standard"
          >
            <Tab
              icon={<HistoryToggleOffIcon />}
              label="Scheduled"
              {...a11yProps(TABS.SCHEDULED)}
              sx={{ marginRight: 2 }}
            />

            <Tab
              icon={<LockClockIcon />}
              label="One time "
              {...a11yProps(TABS.ONE_TIME)}
              sx={{ marginRight: 2 }}
            />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={TABS.SCHEDULED}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id", hide: true },
              { Header: "Name", accessor: "name" },
              { Header: "Interval", accessor: "interval" },
              { Header: "Next Schedule Time", accessor: "nextScheduleTime" },
              { Header: "Schedule", accessor: "schedule" },
              { Header: "Timezone", accessor: "timezone" },
              { Header: "Destination", accessor: "destination" },
              { Header: "Deleted At", accessor: "deletedAt", hide: true },
              { Header: "Active", accessor: "active" },
            ],
            rows: getRows("scheduled"),
          }}
          manualPagination={false}
          isLoading={isLoading}
          columnsFilter={true}
          isClickable={true}
          onCellClick={onCellClick}
        />
      </TabPanel>
      <TabPanel value={value} index={TABS.ONE_TIME}>
        <DataTable
          table={{
            columns: [
              { Header: "ID", accessor: "id", hide: true },
              { Header: "Name", accessor: "name" },
              { Header: "Interval", accessor: "interval" },
              { Header: "Next Schedule Time", accessor: "nextScheduleTime" },
              { Header: "Schedule", accessor: "schedule" },
              { Header: "Timezone", accessor: "timezone" },
              { Header: "Destination", accessor: "destination" },
              { Header: "Date From", accessor: "dateFrom" },
              { Header: "Date To", accessor: "dateTo" },
              { Header: "Deleted At", accessor: "deletedAt", hide: true },
            ],
            rows: getRows("one-time"),
          }}
          manualPagination={false}
          isLoading={isLoading}
          columnsFilter={true}
          isClickable={true}
          onCellClick={onCellClick}
        />
      </TabPanel>
    </SoftBox>
  );
};

export default Scheduler;
