import { fDateTimeSuffix } from "./formatTime";
import _ from "lodash";

export const updateSelectedFilters = (data, fn, options) => {
  const {
    toDate,
    fromDate,
    paymentOptionId,
    pspId,
    merchantId,
    brandId,
    transactionId,
    minAmount,
    maxAmount,
    documentId,
    brandName,
    pan,
    // eslint-disable-next-line no-unused-vars
    paymentOptionName,
    // eslint-disable-next-line no-unused-vars
    sortBy,
    // eslint-disable-next-line no-unused-vars
    sortDirection,
    // eslint-disable-next-line no-unused-vars
    pspName,
    searchArchives,
    ...selected
  } = data;
  const { paymentOptions, merchants, brands, psps } = options;
  let _toDate = toDate;
  let _fromDate = fromDate;

  let time = { toDate: null, fromDate: null };
  if (_toDate) {
    time.toDate = fDateTimeSuffix(_toDate).split(" ");
    time.toDate.splice(2, 1);
  }
  if (_fromDate) {
    time.fromDate = fDateTimeSuffix(_fromDate).split(" ");
    time.fromDate.splice(2, 1);
  }
  let brandN = brands?.find((i) => i._id === brandId)?.name;

  fn((prev) => {
    const paymentOption =
      paymentOptionId && paymentOptions?.find((item) => item.id === paymentOptionId);

    let psp = paymentOption && paymentOption.psps?.find((item) => item.id === pspId)?.name;

    if (psp == undefined || psp == null || _.isEmpty(psp)) {
      psp = psps && psps?.find((item) => item._id === pspId)?.name;
    }
    return {
      ...prev,
      ...selected,
      pspId: psp,
      fromDate:
        _fromDate &&
        `From ${time.fromDate.join(" ") + (_toDate ? " to" + " " + time.toDate.join(" ") : "")} `,
      toDate: _fromDate ? null : _toDate && `To ${time.toDate.join(" ")}`,
      merchantName: merchants?.find((option) => option._id === merchantId)?.name,
      transactionId: transactionId && `Txn ID: ${transactionId}`,
      paymentOptionId: paymentOption && paymentOptionId && paymentOption.name,
      documentId: documentId && `Doc ID: ${documentId}`,
      minAmount: minAmount && `Amount from ${minAmount + (maxAmount ? " to " + maxAmount : "")}`,
      maxAmount: minAmount ? null : maxAmount && `Amount to ${maxAmount}`,
      brandId: brandName ? `Brand: ${brandName}` : brandN && `Brand: ${brandN}`,
      pan: pan && `PAN: ${pan}`,
      searchArchives: searchArchives && "Search Archives",
    };
  });
};
