import { useMutation, useQuery, useQueryClient } from "react-query";

import { getTemplatePath } from "../../utils/string";
import { getMerchant } from "../../utils/localStorage";
const { default: request } = require("../../utils/request");

const GET_PAYOUTS = "/merchants/:merchantId/payouts";
const PROCESS_PAYOUT = "/merchants/:merchantId/payouts/:transactionId/process";
const getPayouts = async () => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_PAYOUTS, { merchantId: merchant?._id });
  return request.get(URL);
};

const processPayout = ({ transactionId, ...payload }) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(PROCESS_PAYOUT, { merchantId: merchant?._id, transactionId });
  return request.post(URL, payload);
};

export const useGetPayouts = () => {
  return useQuery([GET_PAYOUTS], getPayouts);
};

export const useProcessPayout = () => {
  const queryClient = useQueryClient();
  return useMutation(processPayout, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_PAYOUTS]);
    },
  });
};
