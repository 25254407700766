export const Roles = {
  ADMIN: "ADMIN",
  ADMIN_OPERATOR: "ADMIN_OPERATOR",
  SUPER_ADMIN: "SUPER_ADMIN",
};

export const LoginTypes = {
  ADMIN: "ADMIN",
  ORGANIZATION: "ORGANIZATION",
};

export const Permissions = {
  API_KEY: {
    LIST: "6957e5b0-641d-437a-bc94-6b08daf8a6f3",
  },
  BRAND: {
    LIST: "975544a7-49d7-4665-9439-e0bd54509cd0",
  },
  MERCHANT: {
    GET: "67639feb-8bde-4c16-accc-59ae78a8e569",
  },
  MERCHANT_REPORT: {
    LIST: "c920db27-dc66-49a0-8603-56ac7e632aac",
  },
  PAYMENT_OPTION: {
    LIST: "c5378019-5c8d-4721-b585-4b10faf28578",
  },
  PAYOUT: {
    LIST: "01e271dd-f426-482a-9b74-d3b52e1ecdca",
  },
  PAY_BY_LINK: {
    LIST: "b9fdb7d7-46f0-4ed7-abca-a577dd8fe22a",
  },
  MERCHANT_USER: {
    LIST: "1bca37e8-4183-4cc2-adf0-c3573aeb04ba",
  },
  ORGANIZATION_USER: {
    LIST: "1bca37e8-4183-4cc2-adf0-c3573aeb04ba",
  },
};
export const PagePermissions = {
  OrganizationUsers: [Permissions.MERCHANT.GET, Permissions.ORGANIZATION_USER.LIST],
  MerchantReporting: [
    Permissions.MERCHANT.GET,
    Permissions.PAYMENT_OPTION.LIST,
    Permissions.BRAND.LIST,
    Permissions.MERCHANT_REPORT.LIST,
  ],
  ApiKeys: [Permissions.MERCHANT.GET, Permissions.BRAND.LIST, Permissions.API_KEY.LIST],
  MerchantPayout: [Permissions.MERCHANT.GET, Permissions.PAYOUT.LIST],
  PayByLink: [Permissions.MERCHANT.GET, Permissions.BRAND.LIST, Permissions.PAY_BY_LINK.LIST],
  Settings: [Permissions.MERCHANT.GET],
};
