import React from "react";

import { Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { useForm } from "react-hook-form";

import SoftButton from "../../../components/SoftButton";
import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import UserForm from "./UserForm";
import { useCreateOrgUser } from "../hooks";

import { yupResolver } from "@hookform/resolvers/yup";
import { defaultValues, schema } from "../schemas/merchant";
import { useDisclosure } from "../../../hooks/useDisclosure";

const AddOrgUserModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  //form provider
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { handleSubmit } = methods;

  //hooks
  const { isLoading, mutate } = useCreateOrgUser();

  // create a function that handles the form submission
  const onSubmit = async (values) => {
    const { code, ...val } = values;
    mutate(
      { ...val, phone: code + val.phone },
      {
        onSuccess: () => {
          onClose();
        },
      }
    );
  };

  return (
    <>
      <SoftBox pt={2} px={2} mb={3}>
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <SoftButton variant="gradient" color="info" size="small" onClick={onOpen}>
            Add Organization User
          </SoftButton>
        </Stack>
      </SoftBox>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Create New User
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <UserForm />
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}
          >
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Create User
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default AddOrgUserModal;
