import { useMutation, useQuery, useQueryClient } from "react-query";
import { getTemplatePath } from "../../utils/string";
import { toastSuccess } from "../../utils/toast";
import { getMerchant } from "../../utils/localStorage";

const { default: request } = require("../../utils/request");

const GET_API_KEYS = "/merchants/:merchantId/brands/:brandId/api-keys";
const GENERATE_API_KEY = "/merchants/:merchantId/brands/:brandId/api-keys";
const DELETE_API_KEY = "/merchants/:merchantId/brands/:brandId/api-keys/:apiKeyId";
const EDIT_API_KEY = "/merchants/:merchantId/brands/:brandId/api-keys/:apiKeyId";
export const GET_BRANDS = "/merchants/:merchantId/brands";

// api keys
const getApiKeys = (brandId) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_API_KEYS, { merchantId: merchant?._id, brandId });
  return request.get(URL);
};

const generateApiKey = ({ merchantId, brandId }) => {
  const URL = getTemplatePath(GENERATE_API_KEY, { merchantId, brandId });
  return request.post(URL);
};

const getBrands = () => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_BRANDS, { merchantId: merchant?._id });
  return request.get(URL);
};

const deleteApiKey = ({ brandId, apiKeyId }) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(DELETE_API_KEY, {
    merchantId: merchant?._id,
    brandId,
    apiKeyId,
  });
  return request.delete(URL);
};

const editApiKey = ({ brandId, apiKeyId, ...data }) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(EDIT_API_KEY, {
    merchantId: merchant?._id,
    brandId,
    apiKeyId,
  });
  return request.put(URL, data);
};

// brands hooks
export const useGetBrands = () => {
  const merchant = getMerchant();
  return useQuery([GET_BRANDS], () => getBrands());
};

// api keys hooks
export const useEditApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(editApiKey, {
    onSuccess: () => {
      toastSuccess({ description: "Update API key has been success" });
      queryClient.invalidateQueries([GET_API_KEYS]);
    },
  });
};
export const useGetApiKeys = (brandId) => {
  const merchant = getMerchant();
  return useQuery([GET_API_KEYS, merchant?._id, brandId], () => getApiKeys(brandId), {
    enabled: !!(merchant?._id && brandId),
  });
};

export const useGenerateApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(generateApiKey, {
    onSuccess: () => {
      toastSuccess({ description: "Generate API key has been success" });
      queryClient.invalidateQueries([GET_API_KEYS]);
    },
  });
};

export const useDeleteApiKey = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteApiKey, {
    onSuccess: () => {
      toastSuccess({ description: "Delete API key has been success" });
      queryClient.invalidateQueries([GET_API_KEYS]);
    },
  });
};
