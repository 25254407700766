import React, { useState, useEffect } from "react";
import { Box, Stack, Grid, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import PropTypes from "prop-types";
import SoftButton from "../../../components/SoftButton";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

import SoftSelect from "../../../components/SoftSelect";
import { useMerchantStore } from "../../../stores/merchant";
import ColorAndPreview from "./ColorAndPreview";
import LogoUpload from "./LogoUpload";
import { defaultTheme } from "./preview/theme";
import { JsonEditor } from "json-edit-react";

function SettingsColorPresets({
  loading,
  themeColorPresets,
  setThemeColorPresets,
  setCashierTheme,
}) {
  const [theme, setTheme] = useState(defaultTheme);
  const [refresh, setRefresh] = useState(false);
  const [editorTheme, setEditorTheme] = useState("githubLight");
  const { merchant, setSelectedPreviewBrand } = useMerchantStore();
  const [selectedBrand, setSelectedBrand] = useState(null);
  const brand = merchant?.brands.find((brand) => brand._id === selectedBrand?.value);

  const brandOptions = merchant?.brands.map((brand) => ({
    label: brand.name,
    value: brand._id,
  }));

  const onBrandChange = (e) => {
    setSelectedPreviewBrand(e.value);
    const brand = merchant?.brands.find((brand) => brand._id === e.value);
    setSelectedBrand({ label: brand?.name, value: brand?._id });
  };

  const resetDefaultTheme = () => {
    setTheme(defaultTheme);
    setCashierTheme(defaultTheme);
  };

  useEffect(() => {
    if (!selectedBrand?.value) {
      setSelectedBrand({ label: merchant?.brands[0]?.name, value: merchant?.brands[0]?._id });
    }
  }, [merchant]);

  useEffect(() => {
    setRefresh(!refresh);
  }, [editorTheme]);

  useEffect(() => {
    setSelectedPreviewBrand(brand?._id);
    setTheme(brand?.cashierTheme || defaultTheme);
  }, [brand]);

  return (
    <Box sx={{ textAlign: "left", paddingRight: 3 }}>
      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-between"
        alignItems="center"
        width={1}
        spacing={3}
        mb={3}
      >
        <SoftTypography variant="h3">Logo/Style Settings</SoftTypography>
        <SoftBox display="flex" justifyContent="flex-start" gap={2} mt={4}>
          <SoftButton
            variant="gradient"
            color="info"
            type="submit"
            disabled={loading}
            isLoading={loading}
          >
            Save changes
          </SoftButton>
        </SoftBox>
      </Stack>
      <SoftBox p={1}>
        <LogoUpload />
      </SoftBox>
      <SoftTypography variant="overline" sx={{ mb: 2, color: "text.disabled" }}>
        choose your style
      </SoftTypography>
      <SoftBox display="flex" justifyContent="space-between">
        <SoftTypography
          sx={{
            color: (theme) => (theme.palette.mode === "light" ? "secondary" : "primary"),
          }}
        >
          Express your own style with just one click.
        </SoftTypography>
        <SoftBox
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
          height="100%"
          mb={1.5}
        >
          <SoftSelect
            options={brandOptions}
            placeholder={"Select Brand"}
            onChange={onBrandChange}
            value={selectedBrand}
          />
        </SoftBox>
      </SoftBox>
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={5}>
          <SoftBox display="flex" flexDirection="row" justifyContent="space-between">
            <SoftBox height={64} display="flex" flexDirection="row" pl={2} pt={1}>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="githubLight"
                  control={<Radio onChange={() => setEditorTheme("githubLight")} />}
                  label="Light"
                  checked={editorTheme === "githubLight"}
                />
                <FormControlLabel
                  value="githubDark"
                  control={<Radio onChange={() => setEditorTheme("githubDark")} />}
                  label="Dark"
                  checked={editorTheme === "githubDark"}
                />
              </RadioGroup>
            </SoftBox>
            <SoftBox>
              <SoftButton
                variant="outlined"
                color="info"
                size="small"
                sx={{ px: 1, py: 0, mt: 1.5 }}
                onClick={resetDefaultTheme}
              >
                Load default theme
              </SoftButton>
            </SoftBox>
          </SoftBox>
          <SoftBox
            sx={{
              border: "1px solid #e0e0e0",
              borderRadius: 2,
              p: 0.5,
              height: "940px",
              overflowY: "scroll",
            }}
          >
            <JsonEditor
              data={theme}
              setData={setCashierTheme}
              theme={[
                editorTheme,
                {
                  iconEdit: "grey",
                  boolean: {
                    color: "red",
                    fontStyle: "italic",
                    fontWeight: "bold",
                    fontSize: "80%",
                  },
                },
              ]}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={7}>
          <ColorAndPreview
            themeColorPresets={themeColorPresets}
            setThemeColorPresets={setThemeColorPresets}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default SettingsColorPresets;

// Typechecking props of the SettingsColorPresets
SettingsColorPresets.propTypes = {
  setCashierTheme: PropTypes.func,
  loading: PropTypes.bool,
  themeColorPresets: PropTypes.string,
  setThemeColorPresets: PropTypes.func,
};
