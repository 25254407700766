import * as React from "react";
import PropTypes from "prop-types";
import { SelectInputField } from "../FormField";
import { groupedOptions } from "../../constants/sortedCountries";
import { countriesCode } from "../../constants/countriesCode";

export default function SortedCountrySelect({ name, label, required, ...rest }) {
  const formatGroupLabel = (data) => (
    <div>
      <span style={{ fontWeight: 700, color: "#344767", fontSize: "14px" }}>{data.label}</span>
    </div>
  );
  return (
    <>
      <SelectInputField
        {...rest}
        name={name}
        label={label}
        options={groupedOptions}
        required={required}
        formatGroupLabel={formatGroupLabel}
        placeholder="Select country"
        onSelect={(val) => {
          rest?.setValue && rest?.setValue(name, val?.value);
        }}
        grouped={true}
        filterOption={(option, inputValue) =>
          countriesCode
            .filter((i) => i.label.toLowerCase().includes(inputValue?.toLowerCase()))
            .some((i) => i.code === option.value)
        }
      />
    </>
  );
}

SortedCountrySelect.defaultValues = {
  required: false,
  label: "",
};
SortedCountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};
