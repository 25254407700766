import { Box, Grid } from "@mui/material";
import React from "react";
import SoftTypography from "../../../../components/SoftTypography";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";
import ClearIcon from "@mui/icons-material/Clear";

const Header = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <Box
      sx={{
        borderTopRightRadius: "20px",
        borderTopLeftRadius: "20px",
        background: theme.header.background,
        borderBottom: theme.header?.borderBottom,
      }}
      pt={3}
      px={3}
      py={3}
      id="header"
    >
      <Grid container>
        <Grid item xs={11}>
          <Box>
            {merchant.logo && (
              <Box sx={theme.header.logo.wrapper}>
                <Box component="img" src={merchant.logo} alt="logo" sx={theme.header.logo.img} />
              </Box>
            )}
            <Box sx={theme.header?.amountWrapper}>
              {brand?.headerVisibility?.enableTitle && (
                <SoftTypography
                  sx={{ fontSize: "20px", fontWeight: 700, color: theme.header?.color }}
                >
                  Merchant X
                </SoftTypography>
              )}
              {brand?.headerVisibility?.enableAmount && (
                <>
                  <SoftTypography
                    sx={{
                      ...theme.header.amountLabel,
                      color: theme.header.color,
                    }}
                  >
                    Amount:
                  </SoftTypography>
                  <SoftTypography
                    sx={{
                      ...theme.header?.amount,
                      color: theme.header.color,
                    }}
                  >
                    {theme.header.currencyVariant === "symbol" ? "$" : ""} 30{" "}
                    {theme.header.currencyVariant !== "symbol" ? "EUR" : ""}
                  </SoftTypography>
                </>
              )}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={1}>
          <Box>
            <ClearIcon sx={{ fill: theme.header?.color }} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
export default Header;
