import React from "react";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";

const Language = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <SoftBox display={"flex"} justifyContent={"center"} mt={3} gap={1} alignItems={"center"}>
      <SoftTypography sx={{ fontSize: "12px", fontWeight: "bold", color: theme.body.color }}>
        Language
      </SoftTypography>
      <SoftTypography
        sx={{
          fontSize: "16px",
          fontWeight: "regular",
          textDecoration: "underline",
          color: theme.components.MuiTypography.styleOverrides.root.color,
        }}
      >
        EN <ArrowDropDownIcon />
      </SoftTypography>
    </SoftBox>
  );
};
export default Language;
