import { Divider, Grid } from "@mui/material";
import TextInputField from "../../../components/FormField/TextInputField";
import React, { useEffect, useState } from "react";
import SoftButton from "../../../components/SoftButton";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import PeopleIcon from "@mui/icons-material/People";
import { schema } from "../schemas/customer";
import SortedCountrySelect from "../../../components/SortedCountrySelect";

const CustomerForm = ({ handleNextStep, handleGoBack, watch }) => {
  const { name, email, phone, zipCode, city, state, street, streetNumber } = watch();
  const [validate, setValidate] = useState(false);

  useEffect(() => {
    schema
      .isValid({ name, email, phone, zipCode, city, state, street, streetNumber })
      .then((isValid) => {
        setValidate(isValid);
      })
      .catch((error) => {
        console.error("Validation error:", error);
      });
  }, [name, email, phone, zipCode, city, state, street, streetNumber]);
  return (
    <>
      <Grid container display="flex" gap={2} alignItems="center">
        <PeopleIcon />
        <Typography variant="h6">Customer</Typography>
      </Grid>
      <Divider />
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={6} md={4} mb={0}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} mb={0}>
          <TextInputField label="Email" name="email" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} mb={0}>
          <TextInputField label="Phone" name="phone" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInputField label="Zip Code" name="zipCode" />
        </Grid>
        <Grid item xs={12} sm={6} md={4} mb={0}>
          <SortedCountrySelect name="customerCountry" label="Country" isClearable />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInputField label="City" name="city" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInputField label="State" name="state" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInputField label="Street" name="street" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextInputField label="Street Number" name="streetNumber" />
        </Grid>
      </Grid>
      <Grid container display="flex" alignItems="center" justifyContent="center" gap="20px">
        <SoftButton variant="gradient" onClick={handleGoBack}>
          Back
        </SoftButton>
        <SoftButton variant="gradient" color={"info"} onClick={handleNextStep} disabled={!validate}>
          Next
        </SoftButton>
      </Grid>
    </>
  );
};

export default CustomerForm;

CustomerForm.propTypes = {
  handleNextStep: PropTypes.func.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
};
