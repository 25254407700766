import { useMutation, useQuery, useQueryClient } from "react-query";
import { toastError, toastSuccess } from "../../utils/toast";
import request from "../../utils/request";
import { getQueryParam, getTemplatePath } from "../../utils/string";
import jwtDecode from "jwt-decode";
import { getToken } from "../../utils/cache";
import _ from "lodash";
import { LoginTypes } from "../../constants/roles";

const ORG_USERS = "/organizations/:organizationId/users";
const ORG_USER = "/organizations/:organizationId/users/:id";
const RESET_USER_PASSWORD = "/auth/forgot-password";
const GET_RECONCILIATIONS = "/transactions/reconciliation";
const ROLES_BY_LOGIN_TYPE = `/auth/roles/${LoginTypes.ORGANIZATION}`;

const getFilterReconciliations = async (filterReconciliation) => {
  const queryParams = getQueryParam(filterReconciliation);

  return request.get(`${GET_RECONCILIATIONS}?${queryParams}`);
};
const getOrgUsers = () => {
  const organizationId = jwtDecode(getToken())?.organizationId;
  return request.get(getTemplatePath(ORG_USERS, { organizationId }));
};

const createOrgUser = (payload) => {
  const organizationId = jwtDecode(getToken())?.organizationId;
  return request.post(getTemplatePath(ORG_USERS, { organizationId }), payload);
};

const deleteOrgUser = ({ id }) => {
  const organizationId = jwtDecode(getToken())?.organizationId;
  return request.delete(getTemplatePath(ORG_USER, { organizationId, id }));
};
const resetUserPassword = (payload) => request.post(RESET_USER_PASSWORD, payload);

export const useGetOrgUsers = () => {
  return useQuery([ORG_USERS], getOrgUsers);
};
export const useCreateOrgUser = () => {
  const queryClient = useQueryClient();
  return useMutation(createOrgUser, {
    onSuccess: () => {
      toastSuccess({ description: "Create organization has been success" });
      queryClient.invalidateQueries([ORG_USERS]);
    },
  });
};

export const useDeleteOrgUser = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteOrgUser, {
    onSuccess: () => {
      toastSuccess({ description: "Organization User deleted successfully" });
      queryClient.invalidateQueries([ORG_USERS]);
    },
  });
};

export const useResetUserPassword = () => {
  const queryClient = useQueryClient();
  return useMutation(resetUserPassword, {
    onSuccess: () => {
      queryClient.invalidateQueries([ORG_USERS]);
    },
  });
};

export const useGetFilterReconciliations = () => {
  return useMutation(getFilterReconciliations, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};

// get user roles
export const useGetRoles = () => {
  return useQuery([ROLES_BY_LOGIN_TYPE], () => {
    return request.get(ROLES_BY_LOGIN_TYPE);
  });
};
