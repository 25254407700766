import * as Yup from "yup";
import moment from "moment";

export const defaultValues = {
  reportFrom: null,
  reportTo: null,
  generatedAt: null,
};
export const schema = Yup.object().shape({
  reportFrom: Yup.date().nullable(),
  reportTo: Yup.date().nullable(),
  generatedAt: Yup.date().nullable(),
});

export const sanitizeFilters = ({ reportFrom, reportTo, generatedAt }) => {
  return _.pickBy(
    {
      reportFrom: reportFrom && moment(new Date(reportFrom)).format("YYYY-MM-DD HH:mm:ss"),
      reportTo: reportTo && moment(new Date(reportTo)).format("YYYY-MM-DD HH:mm:ss"),
      generatedAt: generatedAt && moment(new Date(generatedAt)).format("YYYY-MM-DD HH:mm:ss"),
    },
    _.identity
  );
};
