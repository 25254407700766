import * as yup from "yup";

export const defaultValues = {
  detailsArray: [],
  amount: null,
  city: null,
  currency: null,
  customerCountry: null,
  description: null,
  dimensions: null,
  email: null,
  enabled: true,
  expiresIn: null,
  merchantReference: "",
  merchantSpecificText: null,
  name: null,
  phone: null,
  productName: null,
  quantity: null,
  state: null,
  street: null,
  streetNumber: null,
  thankYouMessage: null,
  welcomeMessage: null,
  zipCode: null,
};
const EMAIL_REGX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
const nameRegex = /^[A-Za-z\s]+$/;
const numberRegex = /^[0-9+]+$/;
const cityRegex = /^[A-Za-z\s-]*$/;
const streetRegex = /^[A-Za-z0-9\s-]*$/;
const stateRegex = /^[A-Za-z\s]*$/;

export const schema = yup.object().shape({
  customerCountry: yup.string().nullable(),
  detailsArray: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value)),
      dimensions: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value)),
      productName: yup
        .string()
        .nullable()
        .transform((value) => (value === "" ? null : value)),
      quantity: yup
        .number()
        .min(0, "Quantity must be greater than 0")
        .nullable()
        .typeError(() => "Quantity must be a number"),
    })
  ),
  amount: yup
    .number()
    .min(0)
    .required("Amount is required")
    .nullable()
    .typeError(() => "Amount must be a number"),
  city: yup
    .string()
    .matches(cityRegex, "City can only contain letters, spaces, and hyphens")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  currency: yup.string().required("Currency is required"),
  description: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  dimensions: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  email: yup
    .string()
    .matches(EMAIL_REGX, "Invalid email address")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  enabled: yup.boolean(),
  expiresIn: yup
    .mixed()
    .test("is-valid-number", "Expires In must be a valid number greater than 0", function (value) {
      if (value === null) {
        return true; // Null is allowed
      }
      const numericValue = Number(value);
      if (!isNaN(numericValue) && numericValue > 0) {
        return true; // Numeric value greater than 0 is allowed
      }
      return false;
    })
    .nullable()
    .transform((value) => {
      if (value === "") {
        return null; // Convert empty string to null
      }
      return value;
    }),
  merchantReference: yup
    .string()
    .nullable()
    .max(18, "Merchant reference must be at most 18 characters"),
  merchantSpecificText: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  name: yup
    .string()
    .matches(nameRegex, "The name can only contain letters, spaces, and hyphens")
    .test(
      "two-words",
      "The name should consist of a first name and a last name.",
      function (value) {
        if (!value) return true;
        const words = value.split(" ");
        return words.length >= 2;
      }
    )
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  phone: yup
    .string()
    .matches(numberRegex, "Number can only contain digits and a plus sign (+)")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  productName: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  quantity: yup
    .number()
    .min(1)
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  state: yup
    .string()
    .matches(stateRegex, "State can contain letters and spaces")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  street: yup
    .string()
    .matches(streetRegex, "Street can contain letters, numbers, spaces, and hyphens")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  streetNumber: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  thankYouMessage: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  welcomeMessage: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  zipCode: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
});
