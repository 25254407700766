import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import SoftBox from "../SoftBox";
import SoftAvatar from "../SoftAvatar";
import SoftTypography from "../SoftTypography";

import { SelectInputField } from "../FormField";

import { currencies } from "../../constants/currencies";
import { currenciesFlags } from "../../constants/currenciesFlags";

const CurrencySelect = ({ name, label, required, ...rest }) => {
  const currenciesOptions = currencies.slice(1, -1).map((item) => {
    const flag = _.get(currenciesFlags, item.code.toLowerCase());
    return {
      value: item.code,
      label: (
        <SoftBox display="flex" py={1.5} px={2}>
          <SoftBox mr={1}>
            <SoftAvatar src={flag} size="sm" alt="title" />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column" justifyContent="center">
            <SoftTypography
              component="div"
              variant="button"
              textTransform="capitalize"
              fontWeight="medium"
            >
              {item.symbol} {item.name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ),
    };
  });

  //change the display order of currencies
  const filteredCurrencies = currenciesOptions
    .filter((i) => i.value === "USD" || i.value === "EUR")
    .concat(currenciesOptions.filter((i) => i.value !== "USD" && i.value !== "EUR"));

  return (
    <SelectInputField
      name={name}
      label={label}
      options={filteredCurrencies}
      required={required}
      filterOption={(option, inputValue) => {
        return currencies
          .filter((i) => i?.name?.toLowerCase().includes(inputValue?.toLowerCase()))
          .some((i) => i.code === option.value);
      }}
      {...rest}
    />
  );
};

CurrencySelect.defaultValues = {
  required: false,
  label: "",
};
CurrencySelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  label: PropTypes.string,
};

export default CurrencySelect;
