export const sanitizeRequest = ({ name, emails, onlyDeleted }) => {
  return _.pickBy(
    {
      name,
      emails,
      onlyDeleted: `${onlyDeleted || false}`,
    },
    _.identity
  );
};
