import React from "react";
import ShortcutOutlinedIcon from "@mui/icons-material/ShortcutOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";

const InstructionIcon = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  if (theme.instruction.icon.display !== "none") {
    return <ShortcutOutlinedIcon sx={{ color: theme.body.color }} />;
  } else {
    return <FiberManualRecordIcon sx={{ color: theme.body.color, fontSize: "8px !important" }} />;
  }
};
export default InstructionIcon;
