import * as Yup from "yup";

export const defaultValues = {
  name: null,
  surname: null,
  email: null,
  phone: null,
  roles: null,
};

export const schema = Yup.object().shape({
  name: Yup.string()
    .required("Merchant name is required")
    .max(100, "Merchant name is too long, should be less than 100 characters"),
  surname: Yup.string()
    .required("Surname is required")
    .max(100, "Surname is too long, should be under 100 characters"),
  email: Yup.string().required("Email is required"),
  phone: Yup.string()
    .required("Phone number is required")
    .max(100, "Phone number is too long, should be less than 100 characters"),
  roles: Yup.array().of(Yup.string()).required("Role is required"),
});
