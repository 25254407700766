import * as Yup from "yup";
import moment from "moment";

export const defaultValues = {
  name: null,
  fields: ["All"],
  emails: [],
  reportDestination: "s3",
  maxNumberOfTransactionsInCsv: null,
  reportInterval: null,
  // timezone: null,
  nextScheduleTime: null,
  reportSchedule: null,
  dateFrom: null,
  dateTo: null,
  excludeWeekends: false,
  filters: {
    brand: null,
    merchantReference: null,
    status: null,
    paymentOption: null,
    email: null,
  },
};
export const schema = Yup.object().shape({
  name: Yup.string().required("Scheduler name is required"),
  fields: Yup.array().of(Yup.string()).min(1, "Fields are required"),
  emails: Yup.array().of(Yup.string()).min(1, "Emails are required"),
  reportDestination: Yup.string().required("Report destination is required"),
  maxNumberOfTransactionsInCsv: Yup.number().nullable(),
  reportInterval: Yup.string().nullable(),
  // timezone: Yup.string().nullable(),
  nextScheduleTime: Yup.date().nullable(),
  reportSchedule: Yup.string().nullable(),
  dateFrom: Yup.date().nullable(),
  dateTo: Yup.date().nullable(),
  excludeWeekends: Yup.boolean(),
  filters: Yup.object()
    .shape({
      brand: Yup.string().nullable(),
      merchantReference: Yup.string().nullable(),
      status: Yup.string().nullable(),
      paymentOption: Yup.string().nullable(),
      email: Yup.string().nullable(),
    })
    .nullable(),
});

const sanitizeFilters = ({ brand, merchantReference, status, paymentOption, email }) => {
  const sanitizedFilters = _.pickBy(
    {
      brand,
      merchantReference,
      status,
      paymentOption,
      email,
    },
    _.identity
  );
  if (_.isEmpty(sanitizedFilters)) {
    return null;
  }
  return sanitizedFilters;
};

const sanitizeFields = (fields, allFields) => {
  if (fields && fields.includes("All")) {
    return allFields;
  }
  return fields;
};

export const sanitizeRequest = ({
  name,
  fields,
  emails,
  reportDestination,
  maxNumberOfTransactionsInCsv,
  reportInterval,
  // timezone,
  nextScheduleTime,
  reportSchedule,
  dateFrom,
  dateTo,
  excludeWeekends,
  filters,
  allFields,
}) => {
  const payload = _.pickBy(
    {
      name,
      fields: sanitizeFields(fields, allFields),
      emails,
      reportDestination,
      maxNumberOfTransactionsInCsv,
      reportInterval,
      // timezone,
      nextScheduleTime:
        nextScheduleTime && moment(new Date(nextScheduleTime)).format("YYYY-MM-DD HH:mm:ss"),
      reportSchedule,
      dateFrom:
        !reportSchedule &&
        !reportInterval &&
        dateFrom &&
        moment(new Date(dateFrom)).format("YYYY-MM-DD HH:mm:ss"),
      dateTo:
        !reportSchedule &&
        !reportInterval &&
        dateTo &&
        moment(new Date(dateTo)).format("YYYY-MM-DD HH:mm:ss"),
      excludeWeekends,
      filters: sanitizeFilters(filters),
    },
    _.identity
  );

  if (reportSchedule || reportInterval) {
    payload.dateFrom = null;
    payload.dateTo = null;
  }

  if (payload.dateFrom || payload.dateTo) {
    payload.reportSchedule = null;
    payload.reportInterval = null;
  }
  return payload;
};
