import { useMutation, useQuery } from "react-query";
import { getQueryParam, getTemplatePath } from "../../utils/string";
import { toastError, toastSuccess } from "../../utils/toast";
import _ from "lodash";
import { getMerchant } from "../../utils/localStorage";

const { default: request } = require("../../utils/request");

const GET_TRANSACTION = "/merchants/:merchantId/reports/:transactionId";
const GET_CSV = "/merchants/:merchantId/reports/csv-download";
const GET_REPORT = "/merchants/:merchantId/reports";
const SEND_NOTIFICATION = "/notifications/send/:transactionId";
const REFUND = "/refund/by-id";
const GET_MERCHANTS = "/merchants";
// payment options
const GET_PAYMENT_OPTIONS = "/payment-options";

// summary
const GET_TRANSACTION_SUMMARY = "/merchants/:merchantId/reports/summary";

// payment options
const getPaymentOptions = () => {
  return request.get(GET_PAYMENT_OPTIONS);
};

export const getMerchants = () => request.get(GET_MERCHANTS);
export const useGetMerchants = () => {
  return useMutation([GET_MERCHANTS], getMerchants);
};

const getReport = async (filterTransactionProps) => {
  const merchant = getMerchant();
  filterTransactionProps.merchantId = merchant?._id;
  const URL = getTemplatePath(GET_REPORT, filterTransactionProps);
  const params = getQueryParam(_.omit(filterTransactionProps, ["merchantId"]));
  return request.get(`${URL}?${params}`);
};

export const getTransaction = ({ transactionId }) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_TRANSACTION, { transactionId, merchantId: merchant?._id });
  return request.get(URL);
};

export const useGetTransaction = ({ transactionId }) => {
  return useQuery(
    ["transactionId", { transactionId }],
    () => {
      return getTransaction({ transactionId });
    },
    {
      enabled: !!transactionId,
    }
  );
};

const sendNotification = ({ transactionId }) => {
  const URL = getTemplatePath(SEND_NOTIFICATION, { transactionId });
  return request.post(URL);
};
const refund = ({ transactionId, amount }) => {
  return request.post(REFUND, { transactionId, amount });
};
const getCsv = async (filters) => {
  const filteredPayload = _.pickBy(filters, _.identity);
  const URL = getTemplatePath(GET_CSV, { merchantId: filters.merchantId });
  const params = getQueryParam(_.omit(filteredPayload, ["merchantId"]));
  return request.get(`${URL}?${params}`);
};

export const useGetReporting = () => {
  return useMutation(getReport, {
    onError: (error) => {
      const message = _.isArray(error?.response?.data?.message)
        ? _.get(error, "response.data.message[0]")
        : _.get(error, "response.data.message");
      toastError({ description: message });
    },
  });
};

export const useSendNotification = () => {
  return useMutation(sendNotification, {
    onSuccess: () => {
      toastSuccess({ description: "Send notification successfully" });
    },
  });
};

export const useRefund = () => {
  return useMutation(refund, {
    onSuccess: () => {
      toastSuccess({ description: "Send refund successfully" });
    },
  });
};

// payment options
export const useGetPaymentOptions = () => {
  return useQuery(GET_PAYMENT_OPTIONS, getPaymentOptions);
};

export const useGetCsv = () => {
  return useMutation(getCsv, {
    onError: () => {
      toastError({ description: "Failed to download CSV" });
    },
  });
};

// summary

const getSummary = async (filterTransactionProps) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_TRANSACTION_SUMMARY, { merchantId: merchant?._id });
  const params = getQueryParam(filterTransactionProps);
  return request.get(`${URL}?${params}`);
};
export const useGetSummary = () => {
  return useMutation(getSummary, {
    onError: (error) => {
      const message = _.get(error, "response.data.message[0]");
      toastError({ description: message });
    },
  });
};
