import _ from "lodash";

const sanitizeAddress = ({ street, streetNumber, zipCode, city, state, country }) => {
  const address = _.pickBy(
    {
      street,
      streetNumber,
      zipCode,
      city,
      state,
      country,
    },
    _.identity
  );
  return _.isEmpty(address) ? null : address;
};

const sanitizeCustomer = ({ name, email, phone, address }) => {
  const customer = _.pickBy(
    {
      name,
      email,
      phone,
      address,
    },
    _.identity
  );
  return _.isEmpty(customer) ? null : customer;
};

export const sanitizeRequest = ({
  detailsArray,
  zipCode,
  welcomeMessage,
  thankYouMessage,
  streetNumber,
  street,
  state,
  phone,
  name,
  merchantSpecificText,
  merchantReference,
  merchantDomain,
  expiresIn,
  enabled,
  email,
  customerCountry,
  currency,
  city,
  amount,
}) => {
  const address = sanitizeAddress({
    street,
    streetNumber,
    zipCode,
    city,
    state,
    country: customerCountry,
  });

  const customer = sanitizeCustomer({
    name,
    email,
    phone,
    address: address,
  });

  const payload = _.pickBy(
    {
      currency,
      amount,
      customer: customer,
      merchantReference,
      merchantDomain,
    },
    _.identity
  );

  const bodyPayload = {
    enabled,
    payload: payload,
    welcomeMessage,
    thankYouMessage,
    merchantSpecificText,
  };

  if (expiresIn) {
    bodyPayload.orderDetails = detailsArray
      ?.filter((info) => info.productName || info.quantity || info.dimensions || info.description)
      ?.map((info) => {
        return {
          productName: info.productName,
          quantity: Number(info.quantity) === 0 ? null : Number(info.quantity),
          dimensions: info.dimensions,
          description: info.description,
        };
      });
    bodyPayload.expiresIn = Number(expiresIn) === 0 ? null : Number(expiresIn);
  } else {
    bodyPayload.orderDetails = detailsArray?.map((info) => ({
      productName: info.productName,
      quantity: Number(info.quantity) === 0 ? null : Number(info.quantity),
      dimensions: info.dimensions,
      description: info.description,
    }));
  }
  return _.pickBy(bodyPayload, _.identity);
};
