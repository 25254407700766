import { useMutation, useQuery, useQueryClient } from "react-query";
import request from "../../utils/request";
import { getQueryParam, getTemplatePath } from "../../utils/string";
import { getMerchant } from "../../utils/localStorage";
import { toastSuccess, toastError } from "../../utils/toast";
import { saveDataToFile } from "../../utils/file";
import { getToken } from "../../utils/cache";

const SCHEDULED_REPORTS = "/merchants/:merchantId/reports/scheduled-reports";
const SCHEDULED_REPORT = "/merchants/:merchantId/reports/scheduled-report";
const GET_BRANDS = "/merchants/:merchantId/brands";
const GET_PAYMENT_OPTIONS = "/payment-options";
const SCHEDULED_FIELDS = "/merchants/:merchantId/reports/scheduled-fields";
const CREDENTIALS = "/merchants/:merchantId/reports/destinations-credentials";
const CREDENTIAL = "/merchants/:merchantId/reports/destinations-credentials/:id";
const REPORTS = "/merchants/:merchantId/reports/generated-reports";
const DOWNLOAD_REPORT = "/merchants/:merchantId/reports/download-report";

const getScheduledReports = async (payload) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(SCHEDULED_REPORTS, { merchantId: merchant?._id });
  let queryParams = `?${getQueryParam(payload)}`;
  if (queryParams == "?") {
    queryParams = "";
  }
  return request.get(`${URL}${queryParams}`);
};
export const useGetScheduledReports = () => {
  return useMutation(getScheduledReports);
};

const createScheduler = async (payload) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(SCHEDULED_REPORT, { merchantId: merchant?._id });
  return request.post(URL, payload);
};
export const useCreateScheduler = () => {
  return useMutation(createScheduler);
};

const deleteScheduler = async (id) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(SCHEDULED_REPORT, { merchantId: merchant?._id });
  return request.delete(`${URL}/${id}`);
};
export const useDeleteScheduler = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteScheduler, {
    onSuccess: () => {
      toastSuccess({ description: "Scheduler deleted successfully" });
      queryClient.invalidateQueries(SCHEDULED_REPORTS);
    },
  });
};

const updateScheduler = async (payload) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(SCHEDULED_REPORT, { merchantId: merchant?._id });
  return request.patch(`${URL}`, payload);
};
export const useUpdateScheduler = () => {
  const queryClient = useQueryClient();
  return useMutation(updateScheduler, {
    onSuccess: () => {
      toastSuccess({ description: "Scheduler updated successfully" });
      queryClient.invalidateQueries(SCHEDULED_REPORTS);
    },
  });
};

const getBrands = () => {
  const merchant = getMerchant();
  const URL = getTemplatePath(GET_BRANDS, { merchantId: merchant?._id });
  return request.get(URL);
};
export const useGetBrands = () => {
  return useQuery([GET_BRANDS], () => getBrands());
};
const getPaymentOptions = () => {
  return request.get(GET_PAYMENT_OPTIONS);
};
export const useGetPaymentOptions = () => {
  return useQuery(GET_PAYMENT_OPTIONS, getPaymentOptions);
};

const getScheduledFields = (interval) => {
  const merchant = getMerchant();
  let URL = getTemplatePath(SCHEDULED_FIELDS, { merchantId: merchant?._id });
  if (interval) {
    URL = `${URL}?interval=${interval}`;
  }
  return request.get(URL);
};
export const useGetScheduledFields = () => {
  return useMutation(getScheduledFields);
};

export const useGetCredentials = () => {
  const merchant = getMerchant();
  const URL = getTemplatePath(CREDENTIALS, { merchantId: merchant?._id });
  return useQuery([CREDENTIALS], () => request.get(URL));
};

const createCredentials = async (payload) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(CREDENTIALS, { merchantId: merchant?._id });
  return request.post(URL, payload);
};
export const useCreateCredentials = () => {
  const queryClient = useQueryClient();
  return useMutation(createCredentials, {
    onSuccess: () => {
      toastSuccess({ description: "Credential created successfully" });
      queryClient.invalidateQueries(CREDENTIALS);
    },
  });
};

const getReports = async (payload) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(REPORTS, { merchantId: merchant?._id });
  const queryParams = getQueryParam(payload);
  return request.get(`${URL}?${queryParams}`);
};
export const useGetReports = () => {
  return useMutation(getReports);
};

const downloadReport = async ({ reportUrl, reportName }) => {
  const merchant = getMerchant();
  const downloadUrl = getTemplatePath(DOWNLOAD_REPORT, {
    merchantId: merchant?._id,
  });
  const URL = `${process.env.REACT_APP_BASE_URL}${downloadUrl}?reportUrl=${reportUrl}`;
  const response = await fetch(URL, {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  });
  if (response.status > 299) {
    throw new Error(`${response.status} Failed to download report`);
  }
  const blob = await response.blob();
  saveDataToFile(blob, reportName, "application/csv");
};

export const useDownloadReport = () => {
  return useMutation(downloadReport, {
    onError: (error) => {
      toastError({ description: error.message });
    },
  });
};

const deleteCredential = async (id) => {
  const merchant = getMerchant();
  const URL = getTemplatePath(CREDENTIAL, { merchantId: merchant?._id, id });
  return request.delete(URL);
};

export const useDeleteCredential = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteCredential, {
    onSuccess: () => {
      toastSuccess({ description: "Credential deleted successfully" });
      queryClient.invalidateQueries(CREDENTIALS);
    },
  });
};
