import React, { useState } from "react";

import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import SoftBox from "../../components/SoftBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyIcon from "@mui/icons-material/Key";
import TabPanel, { a11yProps } from "../../components/TabPanel";
import Scheduler from "./pages/scheduler";
import Credentials from "./pages/Credentials";
const TABS = {
  DETAILS: 0,
  CREDENTIALS: 1,
};

const Reports = () => {
  const [value, setValue] = useState(TABS.DETAILS);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox sx={{ maxWidth: 550, marginTop: 2, ml: 2, mb: 2 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="scheduler-tabs"
            sx={{ backgroundColor: "transparent", display: { xs: "none", sm: "flex" } }}
            variant="standard"
          >
            <Tab
              icon={<SettingsIcon />}
              label="Scheduler"
              {...a11yProps(TABS.DETAILS)}
              sx={{ marginRight: 2 }}
            />

            <Tab
              icon={<KeyIcon />}
              label="Credentials"
              {...a11yProps(TABS.CREDENTIALS)}
              sx={{ marginRight: 2 }}
            />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={TABS.DETAILS}>
        <Scheduler />
      </TabPanel>
      <TabPanel value={value} index={TABS.CREDENTIALS}>
        <Credentials />
      </TabPanel>
    </MainLayout>
  );
};

export default Reports;
