export const reportDestinationOptions = [{ label: "S3", value: "s3" }];

export const awsRegionsOptions = [
  { label: "us-east-1", value: "us-east-1" },
  { label: "us-east-2", value: "us-east-2" },
  { label: "us-west-1", value: "us-west-1" },
  { label: "us-west-2", value: "us-west-2" },
  { label: "ca-central-1", value: "ca-central-1" },
  { label: "eu-west-1", value: "eu-west-1" },
  { label: "eu-central-1", value: "eu-central-1" },
  { label: "eu-west-2", value: "eu-west-2" },
  { label: "eu-west-3", value: "eu-west-3" },
  { label: "eu-north-1", value: "eu-north-1" },
  { label: "ap-northeast-1", value: "ap-northeast-1" },
  { label: "ap-northeast-2", value: "ap-northeast-2" },
  { label: "ap-southeast-1", value: "ap-southeast-1" },
  { label: "ap-southeast-2", value: "ap-southeast-2" },
  { label: "ap-south-1", value: "ap-south-1" },
  { label: "sa-east-1", value: "sa-east-1" },
  { label: "us-gov-west-1", value: "us-gov-west-1" },
  { label: "us-gov-east-1", value: "us-gov-east-1" },
];

export const s3ACLOptions = [
  { label: "private", value: "private" },
  { label: "public-read", value: "public-read" },
  { label: "public-read-write", value: "public-read-write" },
  { label: "authenticated-read", value: "authenticated-read" },
  { label: "bucket-owner-read", value: "bucket-owner-read" },
  { label: "bucket-owner-full-control", value: "bucket-owner-full-control" },
  { label: "aws-exec-read", value: "aws-exec-read" },
];
