import React, { useState } from "react";
import { Box, Grid, Tab, Tabs } from "@mui/material";
import CustomerForm from "./CustomerForm";
import PaymentForm from "./PaymentForm";
import GeneralSettingsForm from "./GeneralSettingsForm";
import OrderDetailsForm from "./OrderDetailsForm";
import SoftBox from "../../../components/SoftBox";
import AppBar from "@mui/material/AppBar";
import TabPanel, { a11yProps } from "../../../components/TabPanel";
import PaymentIcon from "@mui/icons-material/Payment";
import PeopleIcon from "@mui/icons-material/People";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";

const GenerateLinkForm = (props) => {
  const [currentStep, setCurrentStep] = useState(0);
  const handleNextStep = () => {
    setCurrentStep((prevState) => prevState + 1);
  };
  const handleGoBack = () => {
    setCurrentStep((prevState) => prevState - 1);
  };

  return (
    <Box>
      <Grid>
        <SoftBox sx={{ marginTop: 2 }}>
          <AppBar position="static">
            <Tabs
              value={currentStep}
              aria-label="basic tabs example"
              sx={{ backgroundColor: "transparent" }}
              variant="standard"
            >
              <Tab
                icon={<PaymentIcon />}
                {...a11yProps(0)}
                sx={{ marginRight: 2, cursor: "auto" }}
              />
              <Tab
                icon={<PeopleIcon />}
                {...a11yProps(1)}
                sx={{ marginRight: 2, cursor: "auto" }}
              />
              <Tab
                icon={<SettingsOutlinedIcon />}
                {...a11yProps(2)}
                sx={{ marginRight: 2, cursor: "auto" }}
              />
              <Tab
                icon={<DisplaySettingsOutlinedIcon />}
                {...a11yProps(3)}
                sx={{ marginRight: 2, cursor: "auto" }}
              />
            </Tabs>
          </AppBar>
        </SoftBox>
      </Grid>
      <TabPanel value={currentStep} index={0}>
        <PaymentForm handleNextStep={handleNextStep} {...props} />
      </TabPanel>
      <TabPanel value={currentStep} index={1}>
        <CustomerForm handleNextStep={handleNextStep} handleGoBack={handleGoBack} {...props} />
      </TabPanel>
      <TabPanel value={currentStep} index={2}>
        <GeneralSettingsForm
          handleNextStep={handleNextStep}
          handleGoBack={handleGoBack}
          {...props}
        />
      </TabPanel>
      <TabPanel value={currentStep} index={3}>
        <OrderDetailsForm handleNextStep={handleNextStep} handleGoBack={handleGoBack} {...props} />
      </TabPanel>
    </Box>
  );
};

export default GenerateLinkForm;
