import { Autocomplete, Chip } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import SoftBox from "../SoftBox";
import SoftTypography from "../SoftTypography";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import { TextField } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";

const TagsInput = forwardRef(({ name, label, helperText = null, ...rest }, ref) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => {
        return (
          <Autocomplete
            multiple
            id={`${name}-tags-filled`}
            options={[]}
            value={field.value}
            freeSolo
            renderTags={(value, getTagProps) => {
              return value.map((option, index) => (
                <Chip key={index} variant="outlined" label={option} {...getTagProps({ index })} />
              ));
            }}
            onChange={(event, values) => {
              field.onChange(values);
            }}
            renderInput={(params) => {
              return (
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  height="100%"
                  mb={1.5}
                >
                  {label && (
                    <SoftBox mb={1} ml={0.5} lineHeight={0} display="flex" alignItems="center">
                      <SoftTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {label}
                      </SoftTypography>
                      {helperText && (
                        <Tooltip title={helperText}>
                          <Icon sx={{ fontSize: 10, ml: 0.5 }}>help</Icon>
                        </Tooltip>
                      )}
                    </SoftBox>
                  )}
                  <TextField {...params} />
                  {error && (
                    <SoftBox mt={0.75}>
                      <SoftTypography component="div" variant="caption" color="error">
                        {_.get(error, "message", "Unknown error")}
                      </SoftTypography>
                    </SoftBox>
                  )}
                </SoftBox>
              );
            }}
          />
        );
      }}
    />
  );
});
TagsInput.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};
export default TagsInput;
