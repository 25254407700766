import React from "react";

import { Card } from "@mui/material";

import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";

import { useGetCredentials } from "../../hooks";
import DataTable from "../../../../examples/Tables/DataTable";
import NewCredentialsModal from "../../components/NewCredentialsModal";
import DeleteCredentialModal from "../../components/DeleteCredentialModal";

const Credentials = () => {
  const { data: credentials, isLoading } = useGetCredentials();

  const getRows = () => {
    return _.map(credentials, (item) => ({
      id: item._id,
      name: item.destinationName,
      action: <DeleteCredentialModal id={item._id} />,
    }));
  };

  return (
    <SoftBox>
      <SoftBox fullWidth display="flex" justifyContent="flex-end">
        <NewCredentialsModal />
      </SoftBox>
      <Card sx={{ mt: 2 }}>
        <SoftBox p={2}>
          {credentials?.length > 0 ? (
            <DataTable
              table={{
                columns: [
                  { Header: "ID", accessor: "id" },
                  { Header: "Name", accessor: "name" },
                  { Header: "Action", accessor: "action" },
                ],
                rows: getRows(),
              }}
              manualPagination={false}
              isLoading={isLoading}
              columnsFilter={true}
              isClickable={true}
              showTotalEntries={false}
              canSearch={false}
              entriesPerPage={false}
              //   onCellClick={onCellClick}
            />
          ) : (
            <SoftBox display="flex" justifyContent="center" alignItems="center" height="100%">
              <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
                No credentials found
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      </Card>
    </SoftBox>
  );
};

export default Credentials;
