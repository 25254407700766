import { create } from "zustand";

const initialState = {
  merchant: {
    _id: null,
    name: null,
    legalName: null,
    registrationNumber: null,
    companyEmail: null,
    companyPhone: null,
    companyAddress: null,
    website: null,
    country: null,
    currency: null,
    vat: null,
    mccCode: null,
    bankAccounts: [],
    providerCustomerId: null,
    active: false,
    stores: [],
    createdAt: null,
    updatedAt: null,
    logo: null,
    notificationUrl: null,
    signKey: null,
    brands: [],
    cardBaseCurrency: null,
    paymentFac: false,
    cashierColor: null,
    cashierTheme: null,
    ipWhitelists: [],
  },
  merchants: [],
  setMerchant: () => null,
  setMerchants: () => null,
  setSelectedPreviewBrand: () => null,
  selectedPreviewBrand: null,
};

export const useMerchantStore = create((set) => ({
  ...initialState,
  setMerchant: (values) => {
    return set(({ merchant }) => ({ merchant: { ...merchant, ...values } }));
  },
  setMerchants: (values) => {
    return set(({ merchants }) => ({ merchants: [...merchants, ...values] }));
  },
  setSelectedPreviewBrand: (value) => {
    return set(({ selectedPreviewBrand }) => ({ selectedPreviewBrand: value }));
  },
}));
