import React, { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
// Custom styles for SoftButton
import SoftButtonRoot from "./SoftButtonRoot";

import pxToRem from "../../assets/theme/functions/pxToRem";

// eslint-disable-next-line react/display-name
const SoftButton = forwardRef(
  ({ isLoading, color, variant, size, circular, iconOnly, children, ...rest }, ref) => {
    const renderChildren = () => {
      if (isLoading) {
        return (
          <CircularProgress
            size={pxToRem(18)}
            sx={{ color: (theme) => theme.palette.primary.main }}
          />
        );
      } else {
        return children;
      }
    };
    return (
      <SoftButtonRoot
        {...rest}
        ref={ref}
        color="primary"
        variant={variant === "gradient" ? "contained" : variant}
        size={size}
        ownerState={{ color, variant, size, circular, iconOnly }}
      >
        {renderChildren()}
      </SoftButtonRoot>
    );
  }
);

// Setting default values for the props of SoftButton
SoftButton.defaultProps = {
  size: "medium",
  variant: "contained",
  color: "white",
  circular: false,
  iconOnly: false,
  isLoading: false,
};

// Typechecking props for the SoftButton
SoftButton.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
  color: PropTypes.oneOf([
    "white",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  circular: PropTypes.bool,
  iconOnly: PropTypes.bool,
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default SoftButton;
