import { Chip } from "@mui/material";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { EnabledColorEnum } from "../../constants/status";

// eslint-disable-next-line react/display-name
const EnabledChip = forwardRef(({ label, ...rest }, ref) => {
  const color = EnabledColorEnum[label];
  return <Chip label={label.toString()} {...rest} ref={ref} color={color} />;
});
// Setting default values for the props of SoftPagination
EnabledChip.defaultProps = {
  label: null,
};

// Typechecking props for the SoftPagination
EnabledChip.propTypes = {
  label: PropTypes.bool,
};
export default EnabledChip;
