import React, { useEffect } from "react";
import _ from "lodash";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import DataTable from "../../examples/Tables/DataTable";

import { useGetOrgUsers } from "./hooks";
import AddOrgUserModal from "./components/AddOrgUserModal";
import EnabledChip from "../../components/EnabledChip";
import DeleteOrgUser from "./components/DeleteOrgUser";
import ResetOrgUser from "./components/ResetOrgUser";
import { getToken } from "../../utils/cache";
import { useNavigate } from "react-router-dom";
import Roles from "../../components/Roles";

const OrganizationUsers = () => {
  const { data } = useGetOrgUsers();
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken()) navigate("/sign-in");
  }, []);
  const getRows = () => {
    return _.map(data, (item) => ({
      id: item.id,
      name: item.name,
      surname: item.surname,
      email: item.email,
      promptPassChange: <EnabledChip label={item.promptPassChange} size="small" />,
      roles: <Roles roles={item.roles} />,
      merchantId: item.merchantId,
      actions: (
        <>
          <DeleteOrgUser user={item} />
          <ResetOrgUser user={item} />
        </>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <AddOrgUserModal />
      <DataTable
        table={{
          columns: [
            { Header: "Name", accessor: "name" },
            { Header: "Surname", accessor: "surname" },
            { Header: "Email Address", accessor: "email" },
            { Header: "Prompt Password Change", accessor: "promptPassChange" },
            { Header: "Roles", accessor: "roles" },
            { Header: "actions", accessor: "actions" },
          ],
          rows: getRows() || [],
        }}
      />
    </MainLayout>
  );
};

export default OrganizationUsers;
