export const StatusEnum = {
  INIT: "INIT",
  PENDING: "PENDING",
  USER_CANCEL: "USER_CANCEL",
  PROCESSING: "PROCESSING",
  COMPLETED: "COMPLETED",
  ERROR: "ERROR",
  DECLINED: "DECLINED",
  EXPIRED: "EXPIRED",
  REFUNDED: "REFUNDED",
  CAPTURED: "CAPTURED",
  VOIDED: "VOIDED",
  USER_ABANDONED: "USER_ABANDONED",
  CHARGEBACK: "CHARGEBACK",
};

export const StatusColorEnum = {
  INIT: "default",
  PENDING: "secondary",
  USER_CANCEL: "warning",
  PROCESSING: "secondary",
  COMPLETED: "success",
  ERROR: "error",
  DECLINED: "error",
  EXPIRED: "error",
  REFUNDED: "warning",
  CAPTURED: "warning",
  VOIDED: "warning",
  USER_ABANDONED: "warning",
};

export const EnabledColorEnum = {
  true: "success",
  false: "error",
};

export const statusIcon = {
  PENDING: "pending",
  SUCCESS: "check_circle",
  FAILED: "cancel",
  ERROR: "error",
  DECLINED: "cancel",
  INIT: "not_started",
  VOIDED: "warning",
  CAPTURED: "warning",
  REFUNDED: "keyboard_return",
  EXPIRED: "error",
  PROCESSING: "refresh",
  USER_CANCEL: "warning",
  COMPLETED: "check_circle",
  USER_ABANDONED: "access_time",
};
