import request from "../../utils/request";
import { getTemplatePath } from "../../utils/string";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { toastSuccess } from "../../utils/toast";
import { getMerchant } from "../../utils/localStorage";
import { useMerchantStore } from "../../stores/merchant";

const GET_MERCHANT_BY_ID = "/merchants/:merchantId";
const ADD_MERCHANT_SETTINGS = "/merchants/:merchantId/settings";
const ADD_BRAND_NOTIFICATION = "/merchants/:merchantId/brands/:brandId/settings";

const getMerchantById = () => {
  const merchant = getMerchant();
  return request.get(getTemplatePath(GET_MERCHANT_BY_ID, { merchantId: merchant?._id }));
};

const addMerchantSettings = async (payload) => {
  const merchant = getMerchant();
  return await request.put(
    getTemplatePath(ADD_MERCHANT_SETTINGS, { merchantId: merchant?._id }),
    payload
  );
};

const addBrandNotification = async ({ brandId, ...payload }) => {
  const merchant = getMerchant();
  return await request.patch(
    getTemplatePath(ADD_BRAND_NOTIFICATION, { merchantId: merchant?._id, brandId }),
    payload
  );
};

export const useAddMerchantSettings = () => {
  const queryClient = useQueryClient();
  return useMutation(addMerchantSettings, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MERCHANT_BY_ID]);
      toastSuccess({ description: "Change merchant  settings has been success" });
    },
  });
};

export const useGetMerchantById = () => {
  const merchant = getMerchant();
  const { setMerchant, setSelectedPreviewBrand } = useMerchantStore();
  return useQuery([GET_MERCHANT_BY_ID], getMerchantById, {
    enabled: !!merchant?._id,
    onSuccess: (data) => {
      setMerchant(data);
      // setSelectedPreviewBrand(data?.brands[0]?._id);
    },
  });
};

export const useAddBrandNotification = () => {
  const queryClient = useQueryClient();
  return useMutation(addBrandNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MERCHANT_BY_ID]);
      toastSuccess({ description: "Change brand notification has been success" });
    },
  });
};

const uploadLogo = async ({ formData }) => {
  const merchant = getMerchant();
  return await request.put(
    getTemplatePath(ADD_MERCHANT_SETTINGS, { merchantId: merchant?._id }),
    formData
  );
};

export const useUploadLogo = () => {
  const queryClient = useQueryClient();
  return useMutation(uploadLogo, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MERCHANT_BY_ID]);
      toastSuccess({ description: "Update merchant settings has been success" });
    },
  });
};

export const useUpdateCashierTheme = () => {
  const queryClient = useQueryClient();
  return useMutation(addBrandNotification, {
    onSuccess: () => {
      queryClient.invalidateQueries([GET_MERCHANT_BY_ID]);
    },
  });
};
