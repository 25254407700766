import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import _ from "lodash";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import FormProvider from "../../../components/FormProviders";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import React from "react";
import { useProcessPayout } from "../hooks";
import { toastSuccess } from "../../../utils/toast";

const PayoutActionModal = ({ isOpen, onClose, transaction, type }) => {
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading, mutate } = useProcessPayout();
  const onSubmit = () => {
    mutate(
      { transactionId: transaction.id, action: type.toUpperCase() },
      {
        onSuccess: () => {
          toastSuccess({
            description: type === "approve" ? "Approved successfully" : "Declined successfully",
          });
          onClose();
        },
      }
    );
  };

  return (
    <>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="xs">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            {type === "approve" ? "Approve Payout" : "Decline Payout"}
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox display="flex" alignItems="center">
                      {type === "approve" ? (
                        <CheckCircleRoundedIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.success.main,
                          }}
                        />
                      ) : (
                        <CancelRoundedIcon
                          sx={{
                            width: pxToRem(48),
                            height: pxToRem(48),
                            color: (theme) => theme.palette.error.main,
                          }}
                        />
                      )}

                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          {type === "approve" ? "Approve Payout" : "Decline Payout"}
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          Are you sure you want to {type} payout {_.get(transaction, "id")}? This
                          process is not reverseble.
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Send
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

// Setting default values for the props of PayoutActionModal
PayoutActionModal.defaultProps = {
  isOpen: false,
};

// Typechecking props for the PayoutActionModal
PayoutActionModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  transaction: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
};

export default PayoutActionModal;
