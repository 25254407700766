import React, { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";

import DeleteForever from "@mui/icons-material/DeleteForever";
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from "@mui/material";
import { useDeleteApiKey } from "../hooks";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import SoftButton from "../../../components/SoftButton";

const DeleteApiKey = ({ brandId, apiKeyId }) => {
  const { id: merchantId } = useParams();
  const { mutate, isLoading } = useDeleteApiKey();
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);
  const handleOpen = () => setIsOpen(true);

  const handleDelete = async () => {
    mutate(
      { merchantId, brandId, apiKeyId },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  return (
    <>
      <Tooltip title="Delete">
        <IconButton onClick={handleOpen} disabled={isLoading}>
          <DeleteForever />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs">
        <DialogTitle
          sx={{
            background: (theme) => theme.palette.grey[500],
            fontWeight: (theme) => theme.typography.h6,
            color: (theme) => theme.palette.common.white,
            px: 4,
            py: 1,
          }}
        >
          Confirm
        </DialogTitle>
        <DialogContent>
          <SoftBox p={3} minWidth="300px">
            <SoftTypography variant="h6" textAlign="center">
              Are you sure?
            </SoftTypography>
          </SoftBox>
          <SoftBox display="flex" gap={2} justifyContent="center">
            <SoftButton type="submit" onClick={handleClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="error"
              onClick={handleDelete}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Delete
            </SoftButton>
          </SoftBox>
        </DialogContent>
      </Dialog>
    </>
  );
};

DeleteApiKey.propTypes = {
  brandId: PropTypes.string,
  apiKeyId: PropTypes.string,
};

export default DeleteApiKey;
