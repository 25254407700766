import React, { useEffect, useState } from "react";
import _ from "lodash";

import GenerateApiKey from "./components/GenerateApiKey";
import DeleteApiKey from "./components/DeleteApiKey";
import { Card, Grid } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import DataTable from "../../examples/Tables/DataTable";
import SoftTypography from "../../components/SoftTypography";
import { useGetApiKeys, useGetBrands } from "./hooks";
import SoftBadgeDot from "../../components/SoftBadgeDot";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import SoftSelect from "../../components/SoftSelect";
import { getToken } from "../../utils/cache";
import EditApiKey from "./components/EditApiKey";
import { getMerchant } from "../../utils/localStorage";

const ApiKeys = () => {
  const token = getToken();
  const merchant = getMerchant();
  const [apiKey, setApikey] = useState("");
  const [currentBrand, setCurrentBrand] = useState({});
  const { data: brands } = useGetBrands();
  useEffect(() => {
    if (!token) window.location.href = "/sign-in";
  }, []);

  useEffect(() => {
    if (brands) setCurrentBrand({ value: brands[0]._id, label: brands[0].name });
  }, [brands]);
  const { data: apiKeys } = useGetApiKeys(currentBrand.value);
  const handleChange = (brand) => {
    setCurrentBrand(brand);
  };
  const brandOptions = [{ value: "", label: "All" }]
    .concat(_.map(brands, (item) => ({ value: item._id, label: item.name })))
    .filter((i) => i.label !== "All");
  const getRows = () => {
    return _.map(apiKeys, (item) => ({
      id: item.id,
      prefix: item.prefix,
      active: <SoftBadgeDot color={item.active ? "success" : "light"} />,
      actions: (
        <SoftBox display="flex" gap={1} sx={{ alignItems: "center" }}>
          <DeleteApiKey brandId={currentBrand.value} apiKeyId={item.id} prefix={item.prefix} />
          <EditApiKey
            brandId={currentBrand.value}
            id={item.id}
            merchantId={merchant?._id}
            active={item.active}
            prefix={item.prefix}
          />
        </SoftBox>
      ),
    }));
  };

  return (
    <MainLayout>
      <MainNavbar />
      <SoftBox>
        <Grid
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap={3}
          mt={3}
          mb={3}
        >
          {brands && (
            <Grid item xs={9} sx={{ background: "white" }}>
              <SoftSelect
                defaultValue={{ value: brands[0]?._id, label: brands[0]?.name }}
                options={brandOptions}
                onChange={handleChange}
                sx={{ width: "100%!important" }}
              />
            </Grid>
          )}
          <GenerateApiKey
            merchantId={merchant?._id}
            brandId={currentBrand.value}
            onSuccess={setApikey}
          />
        </Grid>
        <Card sx={{ mt: 2 }}>
          <SoftBox p={3}>
            <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
              API Key
            </SoftTypography>
            <SoftTypography variant="button" fontWeight="regular" color="text" size="md">
              {apiKey}
            </SoftTypography>
          </SoftBox>
        </Card>
        <SoftBox mt={2}>
          <DataTable
            table={{
              columns: [
                { Header: "id", accessor: "id" },
                { Header: "prefix", accessor: "prefix" },
                { Header: "active", accessor: "active" },
                { Header: "actions", accessor: "actions" },
              ],
              rows: getRows(),
            }}
          />
        </SoftBox>
      </SoftBox>
    </MainLayout>
  );
};

export default ApiKeys;
