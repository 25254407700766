import _ from "lodash";
import { useMutation } from "react-query";

import { removeToken, setToken, setMe } from "../../utils/cache";
import request from "../../utils/request";
import { getFirstRoute } from "../../utils/roles";
import { LoginTypes } from "../../constants/roles";

const SIGN_IN_PATH = "/auth/login";
const FORGOT_PASSWORD = "/auth/forgot-password";
const CHANGE_PASSWORD = "/auth/change-password";
const ME = "/auth/me";

const signIn = ({ email, password }) => {
  return request.post(SIGN_IN_PATH, { email, password, type: LoginTypes.ORGANIZATION });
};

const forgotPassword = ({ email }) => {
  return request.post(FORGOT_PASSWORD, { email, type: LoginTypes.ORGANIZATION });
};

const changePassword = ({ password, resetPasswordToken }) => {
  return request.put(CHANGE_PASSWORD, {
    password,
    resetPasswordToken,
    type: LoginTypes.ORGANIZATION,
  });
};
export const useChangePassword = () => {
  return useMutation(changePassword);
};
export const useForgotPassword = () => {
  return useMutation(forgotPassword);
};

export const useSignIn = () => {
  return useMutation(signIn, {
    onSuccess: async (res) => {
      const accessToken = _.get(res, "accessToken");
      setToken(accessToken);
      const meRsp = await request.get(ME);
      setMe(meRsp);
      window.location.href = getFirstRoute() || "/users";
    },
  });
};

export const useLogout = () => {
  const handleLogout = () => {
    removeToken();
    window.location.href = "/sign-in";
  };

  return {
    logout: handleLogout,
  };
};
