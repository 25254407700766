import { Box, Divider, Grid } from "@mui/material";
import _ from "lodash";
import React from "react";

import {
  MultipleSelectField,
  SelectInputField,
  TextInputField,
} from "../../../components/FormField";
import SoftBox from "../../../components/SoftBox";
import { countriesCode } from "../../../constants/countriesCode";
import { useGetRoles } from "../hooks";
import { generateOptions } from "../../../utils/options";
import { useMerchantStore } from "../../../stores/merchant";

const UserForm = () => {
  const { merchants } = useMerchantStore();
  const { data: roles } = useGetRoles();

  const rolesOptions = generateOptions(roles, false);
  const merchantsOptions = generateOptions(merchants, false);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Name" name="name" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Surname" name="surname" />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextInputField label="Email" name="email" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <SoftBox display="flex" gap={2}>
            <SelectInputField
              name="code"
              label="Code"
              options={_.map(countriesCode, (option) => ({
                label: `${option.label} +${option.phone}`,
                value: `+${option.phone}`,
              }))}
              placeholder="Select an option"
            />
            <TextInputField label="Phone" name="phone" />
          </SoftBox>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <MultipleSelectField
            name="roles"
            label="Roles"
            options={rolesOptions}
            placeholder="Select roles"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <MultipleSelectField
            name="merchants"
            label="Merchants"
            options={merchantsOptions}
            placeholder="Select merchants"
          />
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export default UserForm;
