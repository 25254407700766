import React from "react";
import { Card, DialogContent } from "@mui/material";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import PropTypes from "prop-types";
import borders from "../../../assets/theme/base/borders";
import _ from "lodash";
import DataTable from "../../../examples/Tables/DataTable";
import UpdateBrandSetting from "./UpdateBrandSetting";

const NotificationSettings = ({ merchantInfo }) => {
  const { borderWidth, borderColor } = borders;

  const getRows = () => {
    return _.map(merchantInfo.brands, (item) => ({
      id: item._id,
      name: item.name,
      notificationUrl: item.notificationUrl,
      action: (
        <UpdateBrandSetting
          brandId={item._id}
          brandName={item.name}
          notificationUrl={item.notificationUrl}
        />
      ),
    }));
  };
  return (
    <Card sx={{ mt: 2 }}>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexDirection={{ xs: "column", sm: "row" }}
        borderBottom={`${borderWidth[1]} solid ${borderColor}`}
        py={2}
        px={3}
      >
        <SoftBox mx={2} lineHeight={1}>
          <SoftTypography variant="button" fontWeight="regular">
            Brand Notification
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox p={1}>
        <DialogContent>
          <DataTable
            table={{
              columns: [
                { Header: "Brand", accessor: "name" },
                { Header: "notification url", accessor: "notificationUrl" },
                { Header: "action", accessor: "action", width: "30px" },
              ],
              rows: getRows(),
            }}
            entriesPerPage={false}
            showTotalEntries={false}
          />
        </DialogContent>
      </SoftBox>
    </Card>
  );
};

export default NotificationSettings;

NotificationSettings.propTypes = {
  merchantInfo: PropTypes.object,
};
