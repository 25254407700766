import { Chip } from "@mui/material";
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import { StatusColorEnum } from "../../constants/status";

// eslint-disable-next-line react/display-name
const StatusChip = forwardRef(({ label, tooltip, ...rest }, ref) => {
  const color = StatusColorEnum[label];
  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom" sx={{ cursor: "help" }}>
      <Chip label={label} {...rest} ref={ref} color={color} />
    </Tooltip>
  ) : (
    <Chip label={label} {...rest} ref={ref} color={color} />
  );
});
// Setting default values for the props of SoftPagination
StatusChip.defaultProps = {
  label: "",
  tooltip: "",
};

// Typechecking props for the SoftPagination
StatusChip.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
};
export default StatusChip;
