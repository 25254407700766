export const actionIcons = {
  edit: "edit",
  delete: "delete",
  visibility: "visibility",
  reset: "restart_alt",
  notification: "notifications",
  withdrawal: "payment",
  approve: "check_circle",
  decline: "cancel",
};
