import React from "react";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import SoftBox from "../../components/SoftBox";
import SoftTypography from "../../components/SoftTypography";

const mapColor = {
  success: "success.main",
  error: "error.main",
  warning: "warning.main",
  info: "info.main",
};

// eslint-disable-next-line react/prop-types
const ToastMessage = ({ title, description, type = "info", onClick }) => (
  <SoftBox display="flex" onClick={onClick}>
    <ErrorOutlinedIcon sx={{ color: mapColor[type] }} />
    <SoftBox ml={1}>
      {title && <SoftTypography variant="body2">{title}</SoftTypography>}
      {description && (
        <SoftTypography variant="body2" color="secondary">
          {description}
        </SoftTypography>
      )}
    </SoftBox>
  </SoftBox>
);

export default ToastMessage;
