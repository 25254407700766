import React from "react";
import _ from "lodash";
import { toast } from "react-toastify";
import ToastMessage from "../components/ToastMessage";

// Use this without component
export const toastSuccess = (props) =>
  toast(<ToastMessage {...props} type="success" />, { hideProgressBar: true });
export const toastError = (props) =>
  toast(<ToastMessage {...props} type="error" />, { hideProgressBar: true });
export const toastInfo = (props) =>
  toast(<ToastMessage {...props} type="info" />, { hideProgressBar: true });
export const toastWarning = (props) =>
  toast(<ToastMessage {...props} type="warning" />, { hideProgressBar: true });

// Handle error when call api
export const handleError = (errors) => {
  const messageError = _.get(errors, "response.data.errors[0]");
  if (messageError) {
    toastError({ description: messageError });
    return;
  }

  const message = _.get(errors, "message");
  toastError({ description: message });
};
