import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import MainLayout from "../../../../examples/LayoutContainers/MainLayout";
import MainNavbar from "../../../../examples/Navbars/MainNavbar";
import _ from "lodash";
import { useSchedulerStore } from "../../../../stores/scheduler";
import SoftBox from "../../../../components/SoftBox";
import FormProvider from "../../../../components/FormProviders";
import { defaultValues, schema, sanitizeRequest } from "../../schemas/scheduler";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useGetScheduledReports, useUpdateScheduler } from "../../hooks";
import SchedulerForm from "../../components/SchedulerForm";
import SoftButton from "../../../../components/SoftButton";
import DeleteSchedulerModal from "../../components/DeleteSchedulerModal";
import { Card } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AppBar from "@mui/material/AppBar";
import SettingsIcon from "@mui/icons-material/Settings";
import AssessmentIcon from "@mui/icons-material/Assessment";
import TabPanel, { a11yProps } from "../../../../components/TabPanel";
import ScheduledReports from "../scheduled-reports";
import moment from "moment";
import { useLocation } from "react-router-dom";
import EnableSchedulerModal from "../../components/EnableSchedulerModal";

const TABS = {
  DETAILS: 0,
  REPORTS: 1,
};
function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const SchedulerDetails = () => {
  const query = useQuery();
  const tab = query.get("tab");
  const tabIndex = tab === "reports" ? TABS.REPORTS : TABS.DETAILS;
  const { id } = useParams();
  const navigate = useNavigate();
  const { report, setReport } = useSchedulerStore();
  const [value, setValue] = useState(tabIndex);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };
  // form
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;

  // hooks
  const { mutate: mutateReports } = useGetScheduledReports();
  const { mutate: mutateUpdateScheduler, isLoading } = useUpdateScheduler();

  // functions
  const onSubmit = (data) => {
    const sanitizedData = sanitizeRequest(data);
    mutateUpdateScheduler({ _id: id, ...sanitizedData });
  };

  useEffect(() => {
    if (!report?._id) {
      mutateReports(
        {},
        {
          onSuccess: (data) => {
            const report = data.find((item) => item._id === id);
            if (!report) {
              navigate("/reports");
            } else {
              setReport(report);
            }
          },
        }
      );
    } else {
      reset(report);
      if (!report.reportInterval && !report.reportSchedule) {
        methods.setValue("isOneTimeReport", true);
      }
      if (report.nextScheduleTime) {
        const date = moment(report.nextScheduleTime, "DD/MM/YYYY hh:mm:ss A");
        methods.setValue("nextScheduleTime", date.toDate());
      }
    }
  }, [report]);

  return (
    <MainLayout>
      <MainNavbar
        customRoutes={[
          { label: "Reports", route: "reports" },
          { label: report?.name || id, route: "#" },
        ]}
      />
      <SoftBox sx={{ maxWidth: 550, marginTop: 2, ml: 2, mb: 1 }}>
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleTabChange}
            aria-label="scheduler-tabs"
            sx={{ backgroundColor: "transparent", display: { xs: "none", sm: "flex" } }}
            variant="standard"
          >
            <Tab
              icon={<SettingsIcon />}
              label="Scheduler"
              {...a11yProps(TABS.DETAILS)}
              sx={{ marginRight: 2 }}
            />

            <Tab
              icon={<AssessmentIcon />}
              label="Reports"
              {...a11yProps(TABS.REPORTS)}
              sx={{ marginRight: 2 }}
            />
          </Tabs>
        </AppBar>
      </SoftBox>
      <TabPanel value={value} index={TABS.DETAILS}>
        <Card>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <SoftBox p={1}>
              <SchedulerForm changeScheduler={false} isUpdateForm={true} />
              <SoftBox p={2} display="flex" gap={2}>
                {report?.reportSchedule !== null && report?.reportInterval !== null && (
                  <SoftButton
                    variant="contained"
                    color="info"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Save
                  </SoftButton>
                )}
                {!report?.deletedAt && <DeleteSchedulerModal id={id} />}
                {report?.deletedAt && <EnableSchedulerModal id={id} />}
              </SoftBox>
            </SoftBox>
          </FormProvider>
        </Card>
      </TabPanel>
      <TabPanel value={value} index={TABS.REPORTS}>
        <ScheduledReports />
      </TabPanel>
    </MainLayout>
  );
};

export default SchedulerDetails;
