import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import React from "react";
import FormProvider from "../../../components/FormProviders";
import SoftButton from "../../../components/SoftButton";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import _ from "lodash";
import { useDisclosure } from "../../../hooks/useDisclosure";
import SoftTypography from "../../../components/SoftTypography";
import SoftBox from "../../../components/SoftBox";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import PropTypes from "prop-types";
import { useDeleteScheduler } from "../hooks";

const DeleteSchedulerModal = ({ id }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const navigate = useNavigate();

  // hooks
  const { mutate: deleteScheduler, isLoading } = useDeleteScheduler();

  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;

  const onSubmit = () => {
    deleteScheduler(id, {
      onSuccess: () => {
        navigate("/reports");
      },
    });
  };

  return (
    <>
      <SoftButton variant="gradient" color="error" onClick={onOpen}>
        Disable
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
        <FormProvider methods={methods} key="delete-form" id={"delete-form"}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Disable Scheduler
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    flexDirection={{ xs: "column", sm: "row" }}
                  >
                    <SoftBox display="flex" alignItems="center">
                      <CancelRoundedIcon
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          color: (theme) => theme.palette.error.main,
                        }}
                      />

                      <SoftBox ml={2} lineHeight={0}>
                        <SoftTypography variant="h6" fontWeight="medium">
                          Disable Scheduler
                        </SoftTypography>
                        <SoftTypography variant="button" color="text" fontWeight="regular">
                          Are you sure you want to disable this scheduler?
                        </SoftTypography>
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="error"
              onClick={handleSubmit(onSubmit)}
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Disable Scheduler
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

DeleteSchedulerModal.propTypes = {
  id: PropTypes.string,
};

export default DeleteSchedulerModal;
