import React from "react";

import { Card, Grid } from "@mui/material";
import _ from "lodash";

import PropTypes from "prop-types";
import {
  DatePickerField,
  PaymentOptionSelect,
  SelectInputField,
  TextInputField,
  SwitchField,
} from "../../../components/FormField";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import { StatusEnum } from "../../../constants/status";

const MerchantReportingFilters = (props) => {
  const { brands, onClearFilters, paymentOptions } = props;
  const now = new Date();

  const dateTimePickerOptions = {
    allowInput: false,
    enableTime: true,
    maxDate: now,
    time_24hr: true,
  };

  const statusOptions = [{ value: "", label: "All" }].concat(
    _.map(StatusEnum, (item) => ({ value: item, label: item }))
  );
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Transaction ID" name="transactionId" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  name="brandId"
                  label="Brand"
                  message="Required"
                  isClearable
                  options={brands}
                  placeholder="Select brand"
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <SelectInputField
                  name="status"
                  label="Status"
                  options={statusOptions}
                  placeholder="Select an status"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <PaymentOptionSelect
                  name="paymentOptionId"
                  label="Payment Option"
                  paymentOptions={paymentOptions}
                  isClearable={true}
                />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Merchant Reference" name="merchantReference" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Email" name="email" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <DatePickerField
                  name="fromDate"
                  options={dateTimePickerOptions}
                  label="From Date"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePickerField name="toDate" options={dateTimePickerOptions} label="To Date" />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              <Grid item>
                <SoftBox sx={{ pl: 2 }}>
                  <SwitchField name="searchArchives" label="Search Archives" />
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
              <SoftButton
                variant="gradient"
                color="info"
                type="button"
                sx={{ mt: 2 }}
                onClick={onClearFilters}
              >
                Clean filters
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default MerchantReportingFilters;

// Typechecking props of the MerchantReportingFilters
MerchantReportingFilters.propTypes = {
  brands: PropTypes.array,
  onClearFilters: PropTypes.func,
  paymentOptions: PropTypes.array,
};
