import { Box, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";
import Header from "./Header";
import Language from "./Language";
import SubLogo from "./SubLogo";
import CachedOutlinedIcon from "@mui/icons-material/CachedOutlined";
import { defaultTheme } from "./theme";
import ReplyOutlinedIcon from "@mui/icons-material/ReplyOutlined";
import { useMerchantStore } from "../../../../stores/merchant";

const Status = ({ themeColorPresets }) => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  const textColor = theme.components.MuiTypography.styleOverrides.root.color;
  return (
    <Box
      sx={{
        width: "500px",
        backgroundColor: themeColorPresets,
      }}
      id="container"
      py={2}
    >
      <Header />
      <Box
        sx={{
          background: theme.body.background,
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
        p={3}
        id="body"
      >
        <SoftBox id="status" mt={3}>
          <Box
            display="flex"
            flexDirection={theme.status.flexDirection}
            mt={2}
            gap={theme.status.flexDirection === "row" ? 1 : 0}
          >
            <Typography variant="body2" fontSize={"16px"} sx={{ color: textColor }}>
              Session ID:
            </Typography>
            <Typography fontWeight="bold" fontSize={"16px"} sx={{ color: textColor }}>
              06c622cc-d95c-4e50-b1cb-111111111111
            </Typography>
          </Box>
          <Box
            display="flex"
            flexDirection={theme.status.flexDirection}
            mt={2}
            gap={theme.status.flexDirection === "row" ? 1 : 0}
          >
            <Typography variant="body2" fontSize={"16px"} sx={{ color: textColor }}>
              Payment Option:
            </Typography>
            <Typography fontWeight="bold" fontSize={"16px"} sx={{ color: textColor }}>
              Easy P2P
            </Typography>
          </Box>
        </SoftBox>
        <SoftBox display={"flex"} flexDirection="column" alignItems="center" mt={4}>
          <CachedOutlinedIcon fontSize="large" sx={{ color: textColor }} />
          <Typography fontSize={24} fontWeight="bold" sx={{ color: textColor }}>
            EXPIRED
          </Typography>
        </SoftBox>
        <SoftBox>
          <SubLogo />
          <SoftBox display={"flex"} justifyContent={"center"} mt={3}>
            <SoftTypography fontSize={"16px"} sx={{ color: textColor }}>
              The transfer can take up to 20 minutes to complete.
            </SoftTypography>
          </SoftBox>
          {/* Submit Button */}
          <SoftBox display={"flex"} justifyContent={"center"} mt={4}>
            <SoftButton
              startIcon={
                theme.components.MuiButton.icon.display !== "none" ? <ReplyOutlinedIcon /> : null
              }
              sx={{
                borderRadius: "32px",
                color: theme.components.MuiButton.styleOverrides.contained.color,
                fontSize: "0.9375rem",
                fontWeight: 700,
                background: theme.components.MuiButton.styleOverrides.contained.background,
              }}
              fullWidth
            >
              RETURN TO ACCOUNT
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <Language />
      </Box>
    </Box>
  );
};
export default Status;

Status.propTypes = {
  themeColorPresets: PropTypes.string,
};
