import Image from "../Image";
import React from "react";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";

const SubLogo = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <SoftBox mt={2} id="powered-by">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "7px",
          marginTop: "10px",
        }}
      >
        <SoftTypography
          fontSize={12}
          fontWeight={"bold"}
          lineHeight={1.25}
          sx={{ color: theme.components.MuiTypography.styleOverrides.root.color }}
        >
          Powered by
        </SoftTypography>
        <Image src={`/logos/sublogo.svg`} alt="logo" width={23} height={20} />
      </div>
    </SoftBox>
  );
};
export default SubLogo;
