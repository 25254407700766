import { create } from "zustand";

const initialState = {
  report: {
    _id: null,
    name: null,
    fields: null,
    emails: null,
    reportDestination: null,
    maxNumberOfTransactionsInCsv: null,
    nextScheduleTime: null,
    reportInterval: null,
    reportSchedule: null,
    dateFrom: null,
    dateTo: null,
    timeZone: null,
    excludeWeekends: [],
  },
  setReport: () => null,
};

export const useSchedulerStore = create((set) => ({
  ...initialState,
  setReport: (values) => {
    return set(({ report }) => ({ report: { ...report, ...values } }));
  },
}));
