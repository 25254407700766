export const defaultTheme = {
  palette: {
    primary: {
      main: "#00349a",
    },
    secondary: {
      main: "#5D737E",
    },
    error: {
      main: "#f44336",
    },
    background: {
      default: "#fff",
    },
    warning: {
      main: "#ff9800",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    button: {
      fontWeight: "bold",
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: "#4E4E4E",
        },
      },
    },
    MuiBox: {
      styleOverrides: {
        root: {
          backgroundColor: "#fff",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiButton: {
      icon: {
        display: "block",
      },
      defaultProps: {
        size: "large",
      },
      styleOverrides: {
        root: {
          borderRadius: "32px",
        },
        sizeLarge: {
          padding: "12px 24px",
        },
        contained: {
          color: "#fff",
          background: "linear-gradient(90deg, #FF692E -0.03%, #F5B463 100.04%)",
          "&:hover": {
            backgroundColor: "#F5B463",
          },
        },
        outlined: {
          color: "#000",
          border: "1px solid #c2c4be",
          "&:hover": {
            backgroundColor: "#c2c4be",
            color: "#000",
            border: "1px solid #fff",
          },
        },
        startIcon: {
          marginLeft: "8px",
          marginRight: "8px",
          color: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
          color: "#4E4E4E",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "2px solid rgba(0, 0, 0, 0.12)",
          },
          "&:focus .MuiOutlinedInput-notchedOutline": {
            border: "#4E4E4E",
          },
        },
        notchedOutline: {
          borderWidth: "2px",
          border: "2px solid rgba(0, 0, 0, 0.12)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "12px",
          fontWeight: "700",
          marginBottom: "8px",
          textTransform: "uppercase",
          color: "#4E4E4E",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: "#4E4E4E",
          "&.Mui-selected": {
            backgroundColor: "#e0e0e0",
            color: "#4E4E4E",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          color: "#4E4E4E",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fill: "#4E4E4E",
        },
      },
    },
  },
  header: {
    cancelModal: {
      background: "#fff",
      border: "1px solid #4E4E4E",
      color: "#4E4E4E",
    },
    footer: {
      color: "#BCBCBC",
      fontSize: "0.75rem",
      fontWeight: "400",
      textAlign: "center",
    },
    amountWrapper: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "flex-start",
    },
    amount: {
      display: "block",
      fontSize: "24px",
      color: "inherit",
      fontWeight: "700",
    },
    amountLabel: {
      display: "none",
      fontSize: "0.75rem",
      color: "inherit",
    },
    color: "#fff",
    logo: {
      img: {
        maxWidth: { xs: 233, md: 167 },
        maxHeight: { xs: 350, md: 250 },
      },
      wrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100%",
      },
      url: "",
    },
    background:
      "linear-gradient(273.33deg, #25028B -11.72%, #34AEFE 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF)",
    currencyVariant: "symbol",
    borderBottom: "none",
  },
  body: {
    background: "#fff",
    color: "#000",
    border: "2px solid rgba(0, 0, 0, 0.12)",
  },
  paymentMethod: {
    justifyContent: "space-between",
    label: {
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "8px",
      textTransform: "uppercase",
    },
    value: {
      fontSize: "20px",
      lineHeight: "1.7",
      fontWeight: "400",
    },
    instruction: {
      header: {
        textAlign: "flex-start",
        fontSize: "1.3rem",
        fontWeight: "700",
        marginTop: "32px",
        marginBottom: "16px",
      },
      description: {
        textAlign: "center",
        fontSize: "0.75rem",
        fontWeight: "300",
        display: "none",
        marginBottom: "16px",
      },
    },
  },
  countDown: {
    color: "#07bc0c",
  },
  instruction: {
    icon: {
      display: "block",
    },
    header: {
      color: "#07bc0c",
    },
  },
  status: {
    flexDirection: "column",
  },
  paymentOption: {
    icon: {
      variant: "arrow",
    },
    border: "2px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "0.75rem",
    padding: "10px",
  },
  paymentDetails: {
    justifyContent: "space-between",
    input: {
      label: {
        display: "none",
      },
    },
  },
  fawry: {
    paymentMethod: {
      wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        gap: 2,
        mt: 2,
      },
      title: {
        fontSize: "1rem",
        fontWeight: "700",
      },
      description: {
        fontSize: "0.75rem",
        fontWeight: "300",
      },
      logo: {
        width: 110,
        height: 35,
      },
      divider: {
        bgcolor: "#4B566A",
        mt: 2,
        mb: 2,
      },
    },
    body: {
      copyInput: {
        background: "#FFFBEB",
        borderRadius: "7.6875rem",
        color: "#006D95",
        fontSize: "1rem",
        fontWeight: "700",
        border: "2px solid #e4e4e4",
      },
      copyButton: {
        background: "#F8FAFC",
        color: "#5D737E",
        fontSize: "1rem",
        fontWeight: "500",
        border: "2px solid #e4e4e4",
        "&:hover": {
          background: "#F8FAFC",
        },
        borderRadius: "7.6875rem",
      },
    },
  },
  mobileWallet: {
    paymentMethod: {
      wrapper: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-around",
        gap: 2,
        mt: 2,
      },
      title: {
        fontSize: "1rem",
        fontWeight: "700",
      },
      description: {
        fontSize: "0.75rem",
        fontWeight: "300",
      },
      logo: {
        width: 31,
        height: 31,
      },
      divider: {
        bgcolor: "#4B566A",
        mt: 2,
        mb: 2,
      },
    },
    body: {
      copyInput: {
        background: "#FFFBEB",
        border: "1px solid #FDE68A",
        borderRadius: "7.6875rem",
        color: "#006D95",
        fontSize: "1rem",
        fontWeight: "700",
      },
      copyButton: {
        background: "#F8FAFC",
        color: "#5D737E",
        fontSize: "1rem",
        fontWeight: "500",
        border: "1px solid #CBD5E1",
        "&:hover": {
          background: "#F8FAFC",
        },
        borderRadius: "7.6875rem",
      },
      phoneInput: {
        border: "1px solid #FDE68A",
      },
    },
  },
};
