import { Divider, Grid, IconButton } from "@mui/material";
import TextInputField from "../../../components/FormField/TextInputField";
import React from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import SoftButton from "../../../components/SoftButton";
import _ from "lodash";
import Icon from "@mui/material/Icon";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import DisplaySettingsOutlinedIcon from "@mui/icons-material/DisplaySettingsOutlined";

const DetailsArray = () => {
  const { control } = useFormContext();
  const {
    fields: descriptions,
    remove,
    append,
  } = useFieldArray({
    control,
    name: `detailsArray`,
  });

  return (
    <div>
      <Grid container display="flex" alignItems="center" mt={3}>
        <Grid item xs={11} sm={11}>
          <Grid container spacing={2} key={"details"}>
            <Grid item xs={12} sm={6}>
              <TextInputField label={`Product Name`} name={`detailsArray[0].productName`} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label={`Quantity`} name={`detailsArray[0].quantity`} type="number" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label={`Dimensions`} name={`detailsArray[0].dimensions`} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextInputField label={`Description`} name={`detailsArray[0].description`} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {_.map(descriptions, (field, index) =>
        index !== 0 ? (
          <Grid container display="flex" alignItems="center" mt={3}>
            <Grid item xs={11} sm={11}>
              <Grid container spacing={2} key={field.id}>
                <Grid item xs={12} sm={6}>
                  <TextInputField
                    label={`Product Name ${index + 1}`}
                    name={`detailsArray[${index}].productName`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField
                    label={`Quantity ${index + 1}`}
                    name={`detailsArray[${index}].quantity`}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField
                    label={`Dimensions ${index + 1}`}
                    name={`detailsArray[${index}].dimensions`}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextInputField
                    label={`Description ${index + 1}`}
                    name={`detailsArray[${index}].description`}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={0.5} sm={1}>
              <IconButton onClick={() => remove(index)} sx={{ mt: 2 }}>
                <Icon>delete_forever</Icon>
              </IconButton>
            </Grid>
          </Grid>
        ) : null
      )}
      <Grid container spacing={2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={9.5}>
          <SoftButton sx={{ mt: 2, mb: 2 }} onClick={() => append({})}>
            Add details
          </SoftButton>
        </Grid>
      </Grid>
    </div>
  );
};

const OrderDetailsForm = ({ handleGoBack, isLoading }) => {
  return (
    <>
      <Grid container display="flex" gap={2} alignItems="center">
        <DisplaySettingsOutlinedIcon />
        <Typography variant="h6">Order Details</Typography>
      </Grid>
      <Divider />
      <DetailsArray />
      <Grid container display="flex" alignItems="center" justifyContent="center" gap="20px">
        <SoftButton variant="gradient" onClick={handleGoBack}>
          Back
        </SoftButton>
        <SoftButton variant="gradient" color="info" type="submit" isLoading={isLoading}>
          Generate Link
        </SoftButton>
      </Grid>
    </>
  );
};

export default OrderDetailsForm;

OrderDetailsForm.propTypes = {
  handleGoBack: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};
