import React from "react";
import PropTypes from "prop-types";
import { useGenerateApiKey } from "../hooks";
import SoftButton from "../../../components/SoftButton";

const GenerateApiKey = ({ merchantId, brandId, onSuccess }) => {
  const { mutate, isLoading } = useGenerateApiKey();

  const handleGenerate = () => {
    mutate(
      { merchantId, brandId },
      {
        onSuccess: ({ apiKey }) => {
          onSuccess(apiKey);
        },
      }
    );
  };

  return (
    <SoftButton
      color="info"
      variant="gradient"
      onClick={handleGenerate}
      disabled={isLoading}
      isLoading={isLoading}
    >
      Generate API key
    </SoftButton>
  );
};

GenerateApiKey.defaultProps = {
  merchantId: "",
  brandId: "",
};

GenerateApiKey.propTypes = {
  merchantId: PropTypes.string,
  brandId: PropTypes.string,
  onSuccess: PropTypes.func,
};

export default GenerateApiKey;
