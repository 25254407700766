import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { useDisclosure } from "../../../hooks/useDisclosure";
import SoftButton from "../../../components/SoftButton";
import { useEditApiKey } from "../hooks";
import { defaultValues, schema } from "../schemas/editApiKey";
import { yupResolver } from "@hookform/resolvers/yup";
import Switch from "@mui/material/Switch";
import SoftTypography from "../../../components/SoftTypography";
import EditIcon from "@mui/icons-material/Edit";

const EditApiKey = ({ id, brandId, active, prefix }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isActive, setIsActive] = useState(active);
  // query
  const { mutate, isLoading } = useEditApiKey(brandId);

  // hook form
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  // function
  const handleSubmitForm = (values) => {
    mutate(
      {
        brandId,
        apiKeyId: id,
        ...values,
      },
      {
        onSuccess: () => {
          handleClose();
        },
      }
    );
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={onOpen} disabled={isLoading} color="secondary">
          <EditIcon height="18px" />
        </IconButton>
      </Tooltip>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        sx={{ overflow: "visible" }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>Update API Key</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods}>
            <Grid container p={2} display="flex">
              <Grid item xs={12}>
                <SoftTypography variant="p" textTransform="capitalize">
                  Prefix: {prefix}
                </SoftTypography>
              </Grid>
              <Grid item mb={2}>
                <SoftTypography variant="p" textTransform="capitalize">
                  API Key ID: {id}
                </SoftTypography>
              </Grid>
              <Grid
                container
                display="flex"
                alignItems="center"
                sx={{ border: "1px solid", p: 2, borderRadius: "24px" }}
              >
                <Grid item xs={9}>
                  <Grid item>
                    <SoftTypography variant="p" fontWeight="medium" textTransform="capitalize">
                      Enable API_KEY
                    </SoftTypography>
                  </Grid>
                  <Grid item>
                    <SoftTypography variant="p">
                      Doing so will anable it globally across all merchants and payments made with
                      this API Key
                    </SoftTypography>
                  </Grid>
                </Grid>
                <Grid item xs={3}>
                  <Switch
                    defaultChecked={isActive}
                    name="active"
                    onChange={() => {
                      setIsActive(!isActive);
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                  <SoftButton onClick={handleClose}>Cancel</SoftButton>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    onClick={() => {
                      handleSubmitForm({
                        active: isActive,
                      });
                    }}
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Update
                  </SoftButton>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

EditApiKey.propTypes = {
  id: PropTypes.string.isRequired,
  brandId: PropTypes.string.isRequired,
  merchantId: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  prefix: PropTypes.string.isRequired,
};

export default EditApiKey;
