import * as yup from "yup";

export const defaultValues = {
  amount: null,
  currency: null,
  merchantReference: "",
};
export const schema = yup.object().shape({
  amount: yup
    .number()
    .required("Amount is required")
    .nullable()
    .typeError(() => "Amount must be a number"),
  currency: yup.string().required("Currency is required"),
  merchantReference: yup
    .string()
    .nullable()
    .max(18, "Merchant reference must be at most 18 characters"),
});
