export const cashierColors = [
  { name: "default", value: "#ffffff" },
  { name: "purple", value: "#7635dc" },
  { name: "cyan", value: "#1CCAFF" },
  { name: "blue", value: "#2065D1" },
  { name: "orange", value: "#fda92d" },
  { name: "red", value: "#FF3030" },
  { name: "green", value: "#28A745" },
  { name: "yellow", value: "#FFD600" },
  { name: "pink", value: "#F48FB1" },
  { name: "dark", value: "#101115" },
];
