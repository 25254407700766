import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const Roles = ({ roles }) => {
  const rolesName = _.map(roles, (r) => r.name);
  return <>{rolesName.join(", ")}</>;
};
Roles.propTypes = {
  roles: PropTypes.array,
};

export default Roles;
