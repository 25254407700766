import MainNavbar from "../../examples/Navbars/MainNavbar";
import React, { useEffect, useState } from "react";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import { Grid, Tab, Tabs } from "@mui/material";
import SoftBox from "../../components/SoftBox";
import AppBar from "@mui/material/AppBar";
import ColorLensRoundedIcon from "@mui/icons-material/ColorLensRounded";
import EditNotificationsRoundedIcon from "@mui/icons-material/EditNotificationsRounded";
import TabPanel, { a11yProps } from "../../components/TabPanel";
import NotificationSettings from "./components/NotificationSettings";
import { useUploadLogo, useGetMerchantById, useUpdateCashierTheme } from "./hooks";
import { cashierColors } from "../../constants/cashierCollors";
import SettingsColorPresets from "./components/SettingsColorPresets";
import { useForm } from "react-hook-form";
import FormProvider from "../../components/FormProviders";
import { getToken } from "../../utils/cache";
import { useMerchantStore } from "../../stores/merchant";

const SettingsPage = () => {
  const [tab, setTab] = useState(0);
  const [cashierTheme, setCashierTheme] = useState(null);

  const { selectedPreviewBrand } = useMerchantStore();
  const merchantInfo = useGetMerchantById().data;
  const currentCashierColor = cashierColors.find(
    (color) => color.value === merchantInfo?.cashierColor
  );
  const [themeColorPresets, setThemeColorPresets] = useState(
    currentCashierColor?.name || "default"
  );
  const cashierColor = cashierColors.find((color) => color.name === themeColorPresets);

  // hooks
  const { mutate, isLoading } = useUploadLogo();
  const { mutate: updateCashierTheme, isLoading: isLoadingUpdateCashierTheme } =
    useUpdateCashierTheme();

  const methods = useForm();
  const { handleSubmit } = methods;
  useEffect(() => {
    if (merchantInfo) {
      setThemeColorPresets(
        cashierColors.find((color) => color.value === merchantInfo?.cashierColor)?.name
      );
    }
  }, [merchantInfo]);
  const handleSubmitForm = async (values) => {
    const formData = new FormData();
    if (values.logo) {
      formData.append("logo", values.logo);
    }
    formData.append("cashierColor", cashierColor?.value);
    mutate({ formData });
    if (cashierTheme) {
      updateCashierTheme({ brandId: selectedPreviewBrand, cashierTheme: cashierTheme });
    }
  };

  //tab handle
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return getToken() ? (
    <MainLayout>
      <MainNavbar />

      <Grid>
        <SoftBox sx={{ maxWidth: 400, marginTop: 2 }}>
          <AppBar position="static">
            <Tabs
              value={tab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              sx={{ backgroundColor: "transparent" }}
              variant="standard"
            >
              <Tab
                icon={<ColorLensRoundedIcon />}
                label="Color Presets"
                {...a11yProps(0)}
                sx={{ marginRight: 2 }}
              />
              <Tab
                icon={<EditNotificationsRoundedIcon />}
                label="Notification Settings"
                {...a11yProps(1)}
                sx={{ marginRight: 2 }}
              />
            </Tabs>
          </AppBar>
        </SoftBox>
      </Grid>
      <FormProvider methods={methods} onSubmit={handleSubmit(handleSubmitForm)}>
        <TabPanel value={tab} index={0}>
          <SettingsColorPresets
            themeColorPresets={themeColorPresets}
            setThemeColorPresets={setThemeColorPresets}
            loading={isLoading}
            setCashierTheme={setCashierTheme}
          />
        </TabPanel>
      </FormProvider>
      <TabPanel value={tab} index={1}>
        <NotificationSettings merchantInfo={merchantInfo} />
      </TabPanel>
    </MainLayout>
  ) : (
    (window.location.href = "/sign-in")
  );
};

export default SettingsPage;
