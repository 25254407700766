import React, { useState } from "react";
import { Icon } from "@mui/material";

import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

import Dropzone from "react-dropzone";
import SoftDropzoneRoot from "../../../components/SoftDropzone/SoftDropzoneRoot";
import { shortText } from "../../../utils/string";
import { useFormContext } from "react-hook-form";

function LogoUpload() {
  const [uploadedFiles, setUploadedFiles] = useState(null);
  const [preview, setPreview] = useState(null);
  const { setValue } = useFormContext();

  const onFileUpload = (files) => {
    if (files && files.length > 0) {
      setUploadedFiles(files[0]);
      setValue("logo", files[0]);
      setPreview(URL.createObjectURL(files[0]));
    }
  };

  return (
    <SoftBox p={3} display="flex" justifyContent="space-between" gap={2}>
      <SoftBox
        sx={{
          height: "100%",
          minHeight: "130px",
          width: "300px",
          border: "1px solid #e0e0e0",
          borderRadius: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SoftTypography variant="button" fontWeight="regular">
          Preview
        </SoftTypography>
        {preview && (
          <img
            src={preview}
            onLoad={() => {
              URL.revokeObjectURL(preview);
            }}
            style={{ height: "100%", width: "100%", objectFit: "contain" }}
          />
        )}
      </SoftBox>
      <SoftBox sx={{ flexGrow: 1 }}>
        <Dropzone
          onDrop={(acceptedFiles) => onFileUpload(acceptedFiles)}
          maxFiles={1}
          accept={{ "image/jpeg": [], "image/png": [], "image/jpg": [] }}
        >
          {({ getRootProps, getInputProps }) => (
            <SoftDropzoneRoot
              {...getRootProps()}
              sx={{ height: "8rem", cursor: "pointer" }}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
            >
              {uploadedFiles && (
                <SoftTypography variant="caption2">
                  {shortText(uploadedFiles.name, 30)}
                </SoftTypography>
              )}
              {!uploadedFiles && (
                <>
                  <Icon fontSize="large">upload</Icon>
                  <SoftTypography variant="caption">Drag logo file here</SoftTypography>
                </>
              )}

              <SoftBox component="input" name="file" type="file" multiple {...getInputProps()} />
            </SoftDropzoneRoot>
          )}
        </Dropzone>
      </SoftBox>
    </SoftBox>
  );
}

export default LogoUpload;
