import React, { useState } from "react";
import PropTypes from "prop-types";
import Swal from "sweetalert2";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Icon from "@mui/material/Icon";

function TableDownloadCSV({ onDownloadClick }) {
  // state
  const [openMenu, setOpenMenu] = useState(false);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const showAlert = () =>
    Swal.fire({
      title: "Downloading...",
      timer: 30000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  const closeAlert = () => Swal.close();
  const handleDownloadCSV = async () => {
    showAlert();
    await onDownloadClick().then(() => {
      closeAlert();
      handleCloseMenu();
    });
  };
  return (
    <>
      <Icon fontSize="default" sx={{ cursor: "pointer", marginRight: 2 }} onClick={handleOpenMenu}>
        download_for_offline_outlined
      </Icon>

      <Menu
        anchorEl={openMenu}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        keepMounted
      >
        <MenuItem onClick={handleDownloadCSV}>
          <span style={{ fontWeight: "normal" }}>Download as CSV</span>
        </MenuItem>
      </Menu>
    </>
  );
}

// Setting default values for the props of ActionMenu
TableDownloadCSV.defaultProps = {
  data: [],
  headers: [],
  useRowsData: true,
  isServerDownload: false,
  onDownloadClick: () => {},
};

// Typechecking props of the ActionMenu
TableDownloadCSV.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  headers: PropTypes.arrayOf(PropTypes.object),
  useRowsData: PropTypes.bool,
  onDownloadClick: PropTypes.func.isRequired,
  isServerDownload: PropTypes.bool,
  filename: PropTypes.string,
};

export default TableDownloadCSV;
