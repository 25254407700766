import { getMe } from "./cache";
import _ from "lodash";
import routes from "../routes";

export const hasPermission = (permission) => {
  if (!permission || _.isEmpty(permission)) {
    return false;
  }

  const me = getMe();
  const permissionIds = Object.keys(_.get(me, "permissions", {}));
  return permissionIds.includes(permission);
};

export const hasPermissions = (permissions) => {
  if (!permissions || permissions.length == 0) {
    return false;
  }

  const me = getMe();
  const permissionIds = Object.keys(_.get(me, "permissions", {}));
  const intersectionPerms = _.intersection(permissionIds, permissions);

  return intersectionPerms.length == permissions.length;
};

export const getFirstRoute = () => {
  for (const route of routes) {
    if (route.permissionsRequired && hasPermissions(route.permissionsRequired)) {
      return route.route;
    }
  }
};
