import React from "react";

import { Card, Grid } from "@mui/material";
import _ from "lodash";

import { TextInputField, SwitchField, TagsInput } from "../../../components/FormField";
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";

const Filters = () => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} lg={12}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <TextInputField label="Report Name" name="name" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TagsInput name="emails" label="Emails" />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid item>
                <SoftBox sx={{ pl: 2 }}>
                  <SwitchField name="onlyDeleted" label="Get the soft deleted reports" />
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container display="flex" justifyContent="space-between">
              <SoftButton variant="gradient" color="info" type="submit" sx={{ mt: 2 }}>
                Search
              </SoftButton>
            </Grid>
          </SoftBox>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Filters;
