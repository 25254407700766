import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";

import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";
import pxToRem from "../../../assets/theme/functions/pxToRem";
import FormProvider from "../../../components/FormProviders";

import { useResetUserPassword } from "../hooks";
import _ from "lodash";
import EditIcon from "@mui/icons-material/Edit";
import { toastSuccess } from "../../../utils/toast";
import { LoginTypes } from "../../../constants/roles";

const ResetOrgUser = ({ user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  //form provider
  const methods = useForm();
  const { handleSubmit } = methods;
  //hooks
  const { isLoading, mutate } = useResetUserPassword();

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  // create a function that handles the form submission
  const onSubmit = () => {
    mutate(
      { email: user.email, type: LoginTypes.ORGANIZATION },
      {
        onSuccess: () => {
          toastSuccess({
            description: `Password reset link has been sent to ${user.email}, check email! 🙂`,
          });
          handleClose();
          navigate("/users");
        },
      }
    );
  };

  return (
    <>
      <Tooltip title="Reset User">
        <IconButton onClick={handleOpen} disabled={isLoading} color="secondary">
          <EditIcon sx={{ ml: 2 }} />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="xs">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Reset User Password
          </DialogTitle>
          <DialogContent>
            <SoftBox p={3}>
              <SoftBox>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems={{ xs: "flex-start", sm: "center" }}
                      flexDirection={{ xs: "column", sm: "row" }}
                    >
                      <EditIcon
                        sx={{
                          width: pxToRem(48),
                          height: pxToRem(48),
                          color: (theme) => theme.palette.info,
                        }}
                      />
                      <SoftBox display="flex" alignItems="center">
                        <SoftBox ml={2} lineHeight={0}>
                          <SoftTypography variant="h6" fontWeight="medium">
                            Reset User Password
                          </SoftTypography>
                          <SoftTypography variant="button" color="text" fontWeight="regular">
                            Password reset link will be sent to {_.get(user, "email")}?
                          </SoftTypography>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "20px" }}
          >
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={handleClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
            >
              Reset Password
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};

ResetOrgUser.propTypes = {
  user: PropTypes.object.isRequired,
};

export default ResetOrgUser;
