import { useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useForm } from "react-hook-form";
import { useCreateScheduler } from "../hooks";
import React from "react";
import FormProvider from "../../../components/FormProviders";
import SoftButton from "../../../components/SoftButton";
import _ from "lodash";
import { useDisclosure } from "../../../hooks/useDisclosure";
import SchedulerForm from "./SchedulerForm";
import { defaultValues, schema, sanitizeRequest } from "../schemas/scheduler";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";

const NewSchedulerModal = ({ onClose: onCloseCallback }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  //form provider
  const methods = useForm({ defaultValues, resolver: yupResolver(schema) });
  const { handleSubmit, reset } = methods;
  // //hooks
  const { isLoading, mutate: createScheduler } = useCreateScheduler();
  // // create a function that handles the form submission

  const onSubmit = (data) => {
    const sanitizedData = sanitizeRequest(data);
    createScheduler(sanitizedData, {
      onSuccess: () => {
        onCloseCallback();
        onClose();
      },
    });
  };

  useEffect(() => {
    reset(defaultValues);
  }, []);

  return (
    <>
      <SoftButton variant="contained" color="info" sx={{ mt: -1 }} onClick={onOpen}>
        Add New Scheduler
      </SoftButton>
      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle
            sx={{
              background: (theme) => theme.palette.grey[500],
              fontWeight: (theme) => theme.typography.h6,
              color: (theme) => theme.palette.common.white,
              px: 4,
              py: 1,
            }}
          >
            Add New Scheduler
          </DialogTitle>
          <DialogContent>
            <SchedulerForm changeScheduler={true} isUpdateForm={false} />
          </DialogContent>
          <DialogActions>
            <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
              Cancel
            </SoftButton>
            <SoftButton
              variant="gradient"
              color="info"
              type="submit"
              sx={{ mt: 2 }}
              disabled={isLoading}
              isLoading={isLoading}
            >
              Add
            </SoftButton>
          </DialogActions>
        </FormProvider>
      </Dialog>
    </>
  );
};
NewSchedulerModal.propTypes = {
  onClose: PropTypes.func,
};
export default NewSchedulerModal;
