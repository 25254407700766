import _ from "lodash";
export const generateOptions = (data, useAll = true) => {
  const options = _.map(data, (item) => ({ value: item._id, label: item.name }));
  if (useAll) {
    return [{ value: "", label: "All" }].concat(options);
  }
  return options;
};

export const generateOptionsFromList = (list) => {
  return list.map((item) => ({ value: item, label: item }));
};
