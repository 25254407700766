import { useQuery } from "react-query";

const { default: request } = require("../../utils/request");
const { useMerchantStore } = require("../../stores/merchant");
const ORG_MERCHANTS = "/organizations/merchants";

export const getMerchants = () => request.get(ORG_MERCHANTS);
export const useGetMerchants = () => {
  const { setMerchants } = useMerchantStore();
  return useQuery([ORG_MERCHANTS], getMerchants, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      setMerchants(data);
    },
  });
};
