// form
import { FormProvider as Form } from "react-hook-form";
import PropTypes from "prop-types";
import React from "react";

// ----------------------------------------------------------------------
export default function FormProvider({ children, onSubmit, methods }) {
  return (
    <Form {...methods}>
      <form onSubmit={onSubmit}>{children}</form>
    </Form>
  );
}

FormProvider.propTypes = {
  children: PropTypes.any,
  onSubmit: PropTypes.func,
  methods: PropTypes.any,
};
