import React, { useEffect, useState } from "react";
import MainNavbar from "../../examples/Navbars/MainNavbar";
import MainLayout from "../../examples/LayoutContainers/MainLayout";
import DataTable from "../../examples/Tables/DataTable";
import _ from "lodash";
import { countries } from "../../constants/countries";
import { fDateTimeSuffix } from "../../utils/formatTime";
import { useGetPayouts } from "./hooks";
import ActionCell from "../../components/ActionCell";
import PayoutActionModal from "./components/PayoutActionModal";
import { getToken } from "../../utils/cache";

const ACTION = [
  {
    type: "approve",
    title: "Approve Payout",
  },
  {
    type: "decline",
    title: "Decline Payout",
  },
];

const MerchantPayout = () => {
  const { data } = useGetPayouts();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSelectedTransaction, setCurrentSelectedTransaction] = useState({});
  const [type, setType] = useState("");

  useEffect(() => {
    if (!getToken()) window.location.href = "/sign-in";
  }, []);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleModalClick = (id, type) => {
    getSelectedTransaction(id);
    setType(type);
    setIsModalOpen(true);
  };
  const getSelectedTransaction = (id) => {
    const transaction = _.find(data, { id });
    setCurrentSelectedTransaction(transaction);
  };

  const getRows = () => {
    return _.map(data, (item) => {
      return {
        country: _.get(_.find(countries, { code: item.country }), "label", "N/A"),
        currency: item.currency,
        amount: item.amount,
        merchantReference: item.merchantReference,
        method: item.method,
        name: item.name,
        email: item.email,
        createdAt: fDateTimeSuffix(item.createdAt),
        actions: (
          <ActionCell
            actions={ACTION}
            onApproveClick={() => handleModalClick(item.id, "approve")}
            onDeclineClick={() => handleModalClick(item.id, "decline")}
          />
        ),
      };
    });
  };
  return (
    <MainLayout>
      <MainNavbar />
      <DataTable
        table={{
          columns: [
            { Header: "Country", accessor: "country" },
            { Header: "Currency", accessor: "currency" },
            { Header: "Amount", accessor: "amount" },
            { Header: "Merchant Reference", accessor: "merchantReference" },
            { Header: "Name", accessor: "name" },
            { Header: "Email", accessor: "email" },
            { Header: "Created time", accessor: "createdAt" },
            { Header: "Actions", accessor: "actions" },
          ],
          rows: getRows(),
        }}
      />
      <PayoutActionModal
        transaction={currentSelectedTransaction}
        onClose={handleModalClose}
        isOpen={isModalOpen}
        type={type}
      />
    </MainLayout>
  );
};

export default MerchantPayout;
