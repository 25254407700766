import * as yup from "yup";

export const defaultValues = {
  city: null,
  email: null,
  name: null,
  phone: null,
  state: null,
  street: null,
  streetNumber: null,
  zipCode: null,
};
const EMAIL_REGX = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
const nameRegex = /^[A-Za-z\s]+$/;
const numberRegex = /^[0-9+]+$/;
const cityRegex = /^[A-Za-z\s-]*$/;
const streetRegex = /^[A-Za-z0-9\s-]*$/;
const stateRegex = /^[A-Za-z\s]*$/;

export const schema = yup.object().shape({
  city: yup
    .string()
    .matches(cityRegex, "City can only contain letters, spaces, and hyphens")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  email: yup
    .string()
    .matches(EMAIL_REGX, "Invalid email address")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  name: yup
    .string()
    .matches(nameRegex, "The name can only contain letters, spaces, and hyphens")
    .test(
      "two-words",
      "The name should consist of a first name and a last name.",
      function (value) {
        if (!value) return true;
        const words = value.split(" ");
        return words.length >= 2;
      }
    )
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  phone: yup
    .string()
    .matches(numberRegex, "Number can only contain digits and a plus sign (+)")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  state: yup
    .string()
    .matches(stateRegex, "State can contain letters and spaces")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  street: yup
    .string()
    .matches(streetRegex, "Street can contain letters, numbers, spaces, and hyphens")
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  streetNumber: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
  zipCode: yup
    .string()
    .nullable()
    .transform((value) => (value === "" ? null : value)),
});
