export const set = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const get = (key) => {
  const response =
    typeof window !== "undefined" && localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key) ?? "")
      : null;
  return response;
};

export const getMerchant = () => {
  const merchant = get("merchant");
  return merchant || { _id: null, name: null };
};

export const setMerchant = (merchant) => {
  set("merchant", merchant);
};
