import { Box, InputBase, OutlinedInput, Typography } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import SoftButton from "../../../../components/SoftButton";
import Header from "./Header";
import PaymentMethod from "./PaymentMethod";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import DomainVerificationOutlinedIcon from "@mui/icons-material/DomainVerificationOutlined";
import { useMerchantStore } from "../../../../stores/merchant";

import Language from "./Language";
import SubLogo from "./SubLogo";
import { defaultTheme } from "./theme";
import InstructionIcon from "./InstructionIcon";

const Info = ({ themeColorPresets }) => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <Box
      sx={{
        width: "500px",
        backgroundColor: themeColorPresets,
      }}
      id="container"
      py={2}
    >
      <Header />
      <Box
        sx={{
          background: theme.body.background,
          borderBottomLeftRadius: "20px",
          borderBottomRightRadius: "20px",
        }}
        p={3}
        id="body"
      >
        <PaymentMethod />

        <SoftBox id="instruction-and-details" mt={3}>
          {/* instruction */}

          {/* End instruction */}
          <SoftBox id="timmer" display={"flex"} justifyContent={"center"} mt={2}>
            <SoftTypography fontSize="16px" fontWeight="bold" sx={{ color: theme.countDown.color }}>
              Please pay within: 07m : 32s
            </SoftTypography>
          </SoftBox>

          {/* payment amount detail */}
          <SoftBox
            id="details"
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-start"}
            mt={2}
            fullWidth
            gap={2}
          >
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Amount:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                fontWeight={"bold"}
              >
                30
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Currency:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                fontWeight={"bold"}
              >
                RUB
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Card number:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                fontWeight={"bold"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                1111 1111 1111 1111
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Card holder:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                fontWeight={"bold"}
              >
                John Doe
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Bank name:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                fontWeight={"bold"}
              >
                Bank of America
              </SoftTypography>
            </SoftBox>
            <SoftBox
              display="flex"
              gap={1}
              justifyContent={theme.paymentDetails.justifyContent}
              width="100%"
              px={2}
            >
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
              >
                Instructions:
              </SoftTypography>
              <SoftTypography
                fontSize={"18px"}
                sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                fontWeight={"bold"}
              >
                Please provide the last 4 card digits for Card number payment, i.e: 1234.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <SoftBox mt={1}>
            {theme.paymentDetails.input.label.display !== "none" && (
              <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                <SoftTypography
                  component="label"
                  variant="caption"
                  fontWeight="bold"
                  textTransform="capitalize"
                  sx={{ color: theme.components.MuiTypography.styleOverrides.root?.color }}
                >
                  Last 4 digits
                </SoftTypography>
              </SoftBox>
            )}
            <OutlinedInput
              sx={{
                backgroundColor: "inherit !important",
                border: theme.components.MuiOutlinedInput.styleOverrides.notchedOutline.border,
              }}
            />
          </SoftBox>
          {/* End payment amount detail */}
        </SoftBox>
        <SoftBox>
          <SubLogo />
          {/* Submit Button */}
          <SoftBox display={"flex"} justifyContent={"center"} mt={3}>
            <SoftButton
              startIcon={
                theme.components.MuiButton.icon.display !== "none" ? (
                  <DomainVerificationOutlinedIcon />
                ) : null
              }
              sx={{
                borderRadius: "32px",
                color: theme.components.MuiButton.styleOverrides.contained.color,
                fontSize: "0.9375rem",
                fontWeight: 700,
                background: theme.components.MuiButton.styleOverrides.contained.background,
              }}
              fullWidth
            >
              Complete Payment
            </SoftButton>
          </SoftBox>
        </SoftBox>
        <Language />
      </Box>
    </Box>
  );
};
export default Info;

Info.propTypes = {
  themeColorPresets: PropTypes.string,
};
