import React from "react";
import SoftBox from "../../../../components/SoftBox";
import SoftTypography from "../../../../components/SoftTypography";
import { defaultTheme } from "./theme";
import { useMerchantStore } from "../../../../stores/merchant";

const PaymentMethod = () => {
  const { merchant, selectedPreviewBrand } = useMerchantStore();
  const brand = merchant?.brands.find((brand) => brand._id === selectedPreviewBrand);
  const theme = brand?.cashierTheme || defaultTheme;
  return (
    <SoftBox id="payment-method">
      <SoftBox
        display={"flex"}
        justifyContent={theme.paymentMethod.justifyContent}
        gap={1}
        alignItems={"center"}
      >
        <SoftTypography
          sx={{
            fontSize: theme.paymentMethod.label.fontSize,
            fontWeight: theme.paymentMethod.label.fontWeight,
            color: theme.components.MuiTypography.styleOverrides.root.color,
          }}
        >
          Payment Method{theme.paymentMethod.justifyContent === "flex-start" ? ":" : ""}
        </SoftTypography>
        <SoftTypography
          sx={{
            fontSize: theme.paymentMethod.value.fontSize,
            fontWeight: theme.paymentMethod.value.fontWeight,
            color: theme.components.MuiTypography.styleOverrides.root.color,
          }}
        >
          Bank Transfer
        </SoftTypography>
      </SoftBox>
      <SoftBox
        display={"flex"}
        alignItems={"center"}
        justifyContent={theme.paymentMethod.justifyContent}
        gap={1}
      >
        <SoftTypography
          sx={{
            fontSize: theme.paymentMethod.label.fontSize,
            fontWeight: theme.paymentMethod.label.fontWeight,
            color: theme.components.MuiTypography.styleOverrides.root.color,
          }}
        >
          Payment With{theme.paymentMethod.justifyContent === "flex-start" ? ":" : ""}
        </SoftTypography>
        <SoftTypography
          sx={{
            fontSize: theme.paymentMethod.value.fontSize,
            fontWeight: theme.paymentMethod.value.fontWeight,
            color: theme.components.MuiTypography.styleOverrides.root.color,
          }}
        >
          Bank Transfer - CVU
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
};
export default PaymentMethod;
