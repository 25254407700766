import * as Yup from "yup";

export const defaultValues = {
  destinationName: "s3",
  region: null,
  endpoint: null,
  Bucket: null,
  ACL: "private",
  credentials: {
    accessKeyId: null,
    secretAccessKey: null,
  },
};
export const schema = Yup.object().shape({
  destinationName: Yup.string().required("Destination name is required"),
  region: Yup.string().required("Region is required"),
  endpoint: Yup.string().required("Endpoint is required"),
  Bucket: Yup.string()
    .required("Bucket is required")
    .test("is-s3-bucket", "Invalid bucket name", (value) => {
      return !value.includes(" ");
    }),
  ACL: Yup.string().required("ACL is required"),
  credentials: Yup.object().shape({
    accessKeyId: Yup.string()
      .required("Access key ID is required")
      .test("is-s3-access-key", "Invalid access key ID", (value) => {
        return !value.includes(" ");
      }),
    secretAccessKey: Yup.string()
      .required("Secret access key is required")
      .test("is-s3-secret-key", "Invalid secret access key", (value) => {
        return !value.includes(" ");
      }),
  }),
});

export const sanitizeRequest = ({
  destinationName,
  region,
  endpoint,
  Bucket,
  ACL,
  credentials,
}) => {
  const payload = _.pickBy(
    {
      destinationName,
      region,
      endpoint,
      Bucket,
      ACL,
      credentials,
    },
    _.identity
  );
  return {
    s3: payload,
  };
};
