import React, { forwardRef, useEffect, useMemo, useState } from "react";

import PropTypes from "prop-types";

import Select from "react-select";

import colors from "../../assets/theme/base/colors";

import styles from "./styles";
import SoftBox from "../SoftBox";
import { useGetMerchants } from "./hooks";
import _ from "lodash";
import { Icon, Typography } from "@mui/material";
import { getMerchant, setMerchant } from "../../utils/localStorage";
import { shortText } from "../../utils/string";

const MerchantSelect = forwardRef(
  ({ size = "medium", error = false, success = false, ...rest }, ref) => {
    const { light } = colors;
    const merchant = getMerchant();
    const [selectedMerchant, setSelectedMerchant] = useState(merchant);
    // store
    // hooks
    const { data: merchants } = useGetMerchants();
    const options = useMemo(() => {
      return _.map(merchants, (merchant) => ({
        value: merchant._id,
        label: (
          <SoftBox display="flex" gap={1}>
            <SoftBox sx={{ fontSize: "20px" }}>
              <Icon fontSize="inherit">person</Icon>
            </SoftBox>
            <SoftBox>
              <Typography variant="h6"> {shortText(merchant.name, 17)}</Typography>
            </SoftBox>
          </SoftBox>
        ),
      }));
    }, [merchants]);

    // functions

    const onChange = (selectedOption) => {
      const selectedMerchant = _.find(merchants, { _id: selectedOption.value });
      setMerchant(selectedMerchant);
      setSelectedMerchant(selectedMerchant);
      window.location.reload();
    };

    useEffect(() => {
      // local storage is empty
      if (!merchant?._id && merchants && merchants.length > 0) {
        setMerchant(merchants[0]);
        setSelectedMerchant(merchants[0]);
      }

      // local storage is not empty but contains non-exist merchant id
      if (merchant?._id && merchants && merchants.length > 0) {
        const found = _.find(merchants, { _id: merchant?._id });
        if (!found) {
          setMerchant(merchants[0]);
          setSelectedMerchant(merchants[0]);
        }
      }
    }, [merchants, merchant]);
    if (!options || options.length === 0) return null;
    return (
      <SoftBox px={2}>
        <Select
          options={options}
          {...rest}
          ref={ref}
          styles={styles(size, error, success)}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary25: light.main,
              primary: light.main,
            },
          })}
          onChange={onChange}
          value={options.find((option) => option?.value === selectedMerchant._id)}
        />
      </SoftBox>
    );
  }
);

MerchantSelect.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  success: PropTypes.bool,
};

export default MerchantSelect;
