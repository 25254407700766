import React, { useEffect, useState } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import Icon from "@mui/material/Icon";

import FormControlLabel from "@mui/material/FormControlLabel";
import { get } from "../../../utils/localStorage";

function TableSelectColumnMenu({ columns, onChange, filename }) {
  const [openMenu, setOpenMenu] = useState(null);
  const [allSelected, setAllSelected] = useState(true);
  const [selectedMenus, setSelectedMenus] = useState(columns);
  useEffect(() => {
    if (get(filename)) setSelectedMenus(get(filename));
  }, []);

  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(null);
  const handleMenuChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value === "all") {
      if (allSelected) {
        const hideAllColumns = columns.map((col) => ({ ...col, hide: true }));
        setSelectedMenus(hideAllColumns);
        onChange(hideAllColumns);
        setAllSelected(false);
        return;
      } else {
        const showAllColumns = columns.map((col) => ({ ...col, hide: false }));
        setSelectedMenus(showAllColumns);
        onChange(showAllColumns);
        setAllSelected(true);
        return;
      }
    }
    let switchCols = selectedMenus.map((col) => {
      if (col.accessor === value) return { ...col, hide: !col.hide };
      return col;
    });
    onChange(switchCols);
    setSelectedMenus(switchCols);
  };
  const isChecked = (accessor) => {
    const col = selectedMenus.find((menu) => menu.accessor === accessor);
    return !col?.hide;
  };
  return (
    <>
      <Icon fontSize="default" sx={{ cursor: "pointer", marginRight: 2 }} onClick={handleOpenMenu}>
        menu_rounded
      </Icon>
      <Menu
        anchorEl={openMenu}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(openMenu)}
        onClose={handleCloseMenu}
        keepMounted
        onChange={handleMenuChange}
      >
        <MenuItem key={"all"}>
          <FormControlLabel
            value={"all"}
            control={<Checkbox checked={allSelected} />}
            label={<span style={{ fontWeight: "normal" }}>All</span>}
            labelPlacement={"All"}
          />
        </MenuItem>
        {columns.map((column) => (
          <MenuItem key={_.get(column, "Header")}>
            <FormControlLabel
              value={_.get(column, "accessor")}
              control={<Checkbox checked={isChecked(_.get(column, "accessor"))} />}
              label={<span style={{ fontWeight: "normal" }}>{_.get(column, "Header")}</span>}
              labelPlacement={_.get(column, "Header")}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

// Setting default values for the props of ActionMenu
TableSelectColumnMenu.defaultProps = {
  columns: [],
  onChange: () => {},
};

// Typechecking props of the ActionMenu
TableSelectColumnMenu.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  filename: PropTypes.string,
};

export default TableSelectColumnMenu;
