import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useState } from "react";
import React from "react";
import SoftButton from "../../../components/SoftButton";

import _ from "lodash";

import PropTypes from "prop-types";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import { useMerchantStore } from "../../../stores/merchant";
import { Table, TableBody, TableRow } from "@mui/material";
import borders from "../../../assets/theme/base/borders";
import colors from "../../../assets/theme/base/colors";
import DownloadIcon from "@mui/icons-material/Download";
import { useDownloadReport } from "../hooks";

const TBHeader = () => {
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  return (
    <SoftBox component="thead">
      <TableRow>
        <SoftBox
          component="th"
          width={{ xs: "45%", md: "50%" }}
          py={1.5}
          px={1}
          textAlign="left"
          borderBottom={borderBottom}
        >
          <SoftTypography variant="h6" color="text" fontWeight="medium">
            Report Files
          </SoftTypography>
        </SoftBox>
      </TableRow>
    </SoftBox>
  );
};

const Item = ({ label, value, color = "text" }) => (
  <SoftBox key={label} display="flex" py={1} pr={2}>
    <SoftTypography variant="button" fontWeight="bold" textTransform="capitalize">
      {label}: &nbsp;
    </SoftTypography>
    <SoftTypography variant="button" fontWeight="regular" color={color}>
      &nbsp;{value}
    </SoftTypography>
  </SoftBox>
);
Item.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
};

const ReportDetailsModal = ({ report, onClose, isOpen, schedulers }) => {
  const [downloading, setDownloading] = useState(false);
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const scheduler = _.find(schedulers, { _id: report?.scheduledReportId });
  const { merchants } = useMerchantStore();
  const merchant = _.find(merchants, { _id: report?.merchantId });

  //hooks
  const {
    mutate: downloadReport,
    mutateAsync: downloadReportAsync,
    isLoading: isDownloading,
  } = useDownloadReport();

  const handleDownloadReport = (reportUrl, reportName) => {
    setDownloading(reportName);
    downloadReport(
      { reportUrl, reportName },
      {
        onSuccess: () => {
          setDownloading(false);
        },
        onError: () => {
          setDownloading(false);
        },
      }
    );
  };

  const downloadAll = async () => {
    report?.csvMeta?.csvs.forEach(async (file) => {
      await downloadReportAsync({ reportUrl: file.csvsPath, reportName: file.csvName });
    });
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle
        sx={{
          background: (theme) => theme.palette.grey[500],
          fontWeight: (theme) => theme.typography.h6,
          color: (theme) => theme.palette.common.white,
          px: 4,
          py: 1,
        }}
      >
        Report Details
      </DialogTitle>
      <DialogContent>
        <Grid container>
          <Grid item xs={12} md={7}>
            <SoftBox py={2} px={3} display="flex" flexDirection="column">
              <Item label="Report ID" value={report?._id} />
              <Item label="Scheduler" value={scheduler?.name} />
              <Item label="Merchant" value={merchant?.name} />
              <Item label="Precessed" value={report?.csvMeta?.precessed} color="success" />
            </SoftBox>
          </Grid>
          <Grid item xs={12} md={5}>
            <SoftBox p={2} display="flex" flexDirection="column">
              <Item label="Generated At" value={report?.generatedAt} />
              <Item label="Report From" value={report?.reportFrom} />
              <Item label="Report To" value={report?.reportTo} />
              <Item label="Created At" value={report?.createdAt} />
            </SoftBox>
          </Grid>
        </Grid>
        <SoftBox p={2}>
          <SoftBox p={3}>
            <SoftBox width="100%" overflow="auto">
              <Table sx={{ width: "inherit" }}>
                <TBHeader />
                <TableBody>
                  {report?.csvMeta?.csvs?.map((file) => (
                    <TableRow key={file.csvsPath}>
                      <SoftBox component="td" textAlign="left" p={1} borderBottom={borderBottom}>
                        <SoftTypography
                          variant="body2"
                          color="text"
                          sx={{ wordWrap: "break-word", width: "700px" }}
                        >
                          {file.csvName}
                        </SoftTypography>
                      </SoftBox>

                      <SoftBox
                        component="td"
                        textAlign="left"
                        py={1}
                        pr={1}
                        pl={3}
                        borderBottom={borderBottom}
                      >
                        <SoftButton
                          variant="gradient"
                          color="info"
                          sx={{ mt: 2 }}
                          onClick={() => handleDownloadReport(file.csvsPath, file.csvName)}
                          isLoading={downloading === file.csvName}
                        >
                          <DownloadIcon />
                          Download
                        </SoftButton>
                      </SoftBox>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </DialogContent>
      <DialogActions>
        <SoftButton variant="gradient" sx={{ mt: 2 }} onClick={onClose}>
          Close
        </SoftButton>
        <SoftButton
          variant="gradient"
          color="info"
          sx={{ mt: 2 }}
          isLoading={isDownloading && downloading == false}
          onClick={downloadAll}
        >
          <DownloadIcon />
          Download All
        </SoftButton>
      </DialogActions>
    </Dialog>
  );
};
ReportDetailsModal.propTypes = {
  report: PropTypes.object,
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  schedulers: PropTypes.array,
};
export default ReportDetailsModal;
