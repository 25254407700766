import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import FormProvider from "../../../components/FormProviders";
import PropTypes from "prop-types";
import { useDisclosure } from "../../../hooks/useDisclosure";
import SoftButton from "../../../components/SoftButton";
import EditIcon from "@mui/icons-material/Edit";
import TextInputField from "../../../components/FormField/TextInputField";
import { useAddBrandNotification } from "../hooks";

const sanitizePayload = ({ brandId, notificationUrl }) => {
  return { brandId, notificationUrl };
};

const UpdateBrandSetting = ({ brandId, brandName, notificationUrl }) => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const methods = useForm();
  const { reset, handleSubmit } = methods;
  const { mutateAsync, isLoading } = useAddBrandNotification();

  const onSubmit = async (values) => {
    const payload = sanitizePayload(values);
    await mutateAsync(payload);
    onClose();
  };
  useEffect(() => {
    reset({ brandName, notificationUrl, brandId });
  }, [brandName]);
  return (
    <>
      <Tooltip title="Edit">
        <IconButton onClick={onOpen} disabled={isLoading} color="secondary">
          <EditIcon height="18px" />
        </IconButton>
      </Tooltip>
      <Dialog open={isOpen} onClose={onClose} sx={{ overflow: "visible" }} fullWidth maxWidth="md">
        <DialogTitle>Update Brand Settings</DialogTitle>
        <DialogContent sx={{ overflow: "visible" }}>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container p={2} display="flex">
              <Grid item xs={12}>
                <TextInputField name="brandName" label="Brand Name" disabled />
              </Grid>
              <Grid item xs={12}>
                <TextInputField name="notificationUrl" label="Notification Url" />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" spacing={1} justifyContent="center" mt={4}>
                  <SoftButton onClick={onClose}>Cancel</SoftButton>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    type="submit"
                    disabled={isLoading}
                    isLoading={isLoading}
                  >
                    Update
                  </SoftButton>
                </Stack>
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};

UpdateBrandSetting.propTypes = {
  brandId: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  notificationUrl: PropTypes.string.isRequired,
};

export default UpdateBrandSetting;
