import { useMutation, useQuery, useQueryClient } from "react-query";
import { toastSuccess } from "../../utils/toast";
import request from "../../utils/request";
import { getTemplatePath } from "../../utils/string";
import { getMerchant } from "../../utils/localStorage";

//get links
const GET_LINKS = "/merchants/:merchantId/brand/:brandId/pay-by-link";
const getLinks = (brandId) => {
  const merchant = getMerchant();
  return request.get(getTemplatePath(GET_LINKS, { merchantId: merchant?._id, brandId }));
};

export const useGetLinks = (brandId) => {
  const merchant = getMerchant();
  return useQuery([GET_LINKS, merchant?._id, brandId], () => getLinks(brandId), {
    enabled: !!(merchant?._id && brandId),
  });
};

//get settings
const GET_SETTINGS = "/merchants/:merchantId/brand/:brandId/pay-by-link/settings";
const getSettings = (brandId) => {
  const merchant = getMerchant();
  return request.get(getTemplatePath(GET_SETTINGS, { merchantId: merchant?._id, brandId }));
};

export const useGetSettings = (brandId) => {
  const merchant = getMerchant();
  return useQuery([GET_SETTINGS, merchant?._id, brandId], () => getSettings(brandId), {
    enabled: !!(merchant?._id && brandId),
  });
};
//update settings

const UPDATE_SETTINGS = "/merchants/:merchantId/brand/:brandId/pay-by-link/settings";
const updateSettings = ({ brandId, payload }) => {
  const merchant = getMerchant();
  return request.put(
    getTemplatePath(UPDATE_SETTINGS, { merchantId: merchant?._id, brandId }),
    payload
  );
};

export const useUpdateSettings = (brandId) => {
  const queryClient = useQueryClient();
  const merchant = getMerchant();

  return useMutation(updateSettings, {
    onSuccess: () => {
      toastSuccess({ description: "Update settings has been success" });
      queryClient.invalidateQueries([GET_SETTINGS, merchant?._id, brandId]);
    },
  });
};

//create link

const CREATE_LINK = "/merchants/:merchantId/brand/:brandId/pay-by-link";
const createLink = ({ brandId, payload }) => {
  const merchant = getMerchant();
  return request.post(
    getTemplatePath(CREATE_LINK, { merchantId: merchant?._id, brandId }),
    payload
  );
};

export const useCreateLink = (brandId) => {
  const queryClient = useQueryClient();
  const merchant = getMerchant();

  return useMutation(createLink, {
    onSuccess: () => {
      toastSuccess({ description: "Create link has been success" });
      queryClient.invalidateQueries([GET_LINKS, merchant?._id, brandId]);
    },
  });
};

//delete link
const DELETE_LINK = "/merchants/:merchantId/brand/:brandId/pay-by-link/:payByLinkId";

const deleteLink = ({ brandId, payByLinkId }) => {
  const merchant = getMerchant();
  return request.delete(
    getTemplatePath(DELETE_LINK, { merchantId: merchant._id, brandId, payByLinkId })
  );
};

export const useDeleteLink = (brandId) => {
  const queryClient = useQueryClient();
  const merchant = getMerchant();

  return useMutation(deleteLink, {
    onSuccess: () => {
      toastSuccess({ description: "Link deleted successfully" });
      queryClient.invalidateQueries([GET_LINKS, merchant?._id, brandId]);
    },
  });
};

// edit link
const EDIT_LINK = "/merchants/:merchantId/brand/:brandId/pay-by-link/:payByLinkId";
const editLink = ({ brandId, payByLinkId, payload }) => {
  const merchant = getMerchant();
  return request.post(
    getTemplatePath(EDIT_LINK, { merchantId: merchant?._id, brandId, payByLinkId }),
    payload
  );
};

export const useEditLink = (brandId) => {
  const queryClient = useQueryClient();
  const merchant = getMerchant();

  return useMutation(editLink, {
    onSuccess: () => {
      toastSuccess({ description: "Edit link has been success" });
      queryClient.invalidateQueries([GET_LINKS, merchant?._id, brandId]);
    },
  });
};
