import React, { useMemo } from "react";
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

import SoftBox from "../../../components/SoftBox";

import StatusSummary from "./StatusSummary";
import Card from "@mui/material/Card";
import SoftTypography from "../../../components/SoftTypography";
import Tooltip from "@mui/material/Tooltip";
import SoftButton from "../../../components/SoftButton";
import Icon from "@mui/material/Icon";
import ComplexReportsDoughnutChartItem from "../../../examples/Charts/DoughnutCharts/ComplexReportsDoughnutChart/ComplexReportsDoughnutChartItem";
import { currenciesFlags } from "../../../constants/currenciesFlags";
import wave from "../../../assets/images/shapes/pattern-lines.svg";

function CurrencyReport({ data }) {
  const renderItems = data?.byCurrency
    ? data.byCurrency.map((item, key) => (
        <Grid item xs={12} lg={6} key={item.code + key}>
          <ComplexReportsDoughnutChartItem
            image={currenciesFlags[item.code.toLowerCase()] || wave}
            title={item.code}
            percentage={`${item.total}`}
            hasBorder={key !== data?.byCurrency.length - 1}
          />
        </Grid>
      ))
    : null;

  return (
    <SoftBox py={3}>
      <SoftBox mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Card sx={{ height: "100%" }}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={2}
                px={2}
              >
                <SoftTypography variant="h6">Summary by currency</SoftTypography>
                <Tooltip title="Summary amount by currency" placement="right">
                  <SoftButton variant="outlined" color="secondary" size="small" circular iconOnly>
                    <Icon>priority_high</Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>

              <SoftBox position="relative" p={2}>
                <Grid container display="flex" flexWrap="wrap" spacing={2}>
                  {useMemo(() => renderItems, [data])}
                </Grid>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}
// Setting default values for the props of SummaryReport
CurrencyReport.defaultProps = {
  data: {},
};

// Typechecking props of the SummaryReport
CurrencyReport.propTypes = {
  data: PropTypes.object,
};

export default CurrencyReport;
